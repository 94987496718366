<template>
    <div class="register-page">
        <app-navbar></app-navbar>
        <div class="wrapper wrapper-full-page ">
            <div class="full-page register-page section-image" filter-color="black">
                <div class="content">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-5 col-md-5 ml-auto">
                                <info-section class="mt-3"
                                              type="primary"
                                              :title="$t('registerPage.infos.header1')"
                                              :description="$t('registerPage.infos.text1')"
                                              icon="fa fa-list-alt">

                                </info-section>
                                <info-section type="primary"
                                              :title="$t('registerPage.infos.header2')"
                                              :description="$t('registerPage.infos.text2')"
                                              icon="fa fa-graduation-cap">

                                </info-section>

                                <info-section type="primary"
                                              :title="$t('registerPage.infos.header3')"
                                              :description="$t('registerPage.infos.text3')"
                                              icon="fa fa-microchip">

                                </info-section>
                            </div>
                            <div class="col-lg-5 col-md-6 mr-auto">
                                <form>
                                    <card type="signup" class="text-center">
                                        <template slot="header">
                                            <h4 class="card-title">{{ $t('registerPage.header')}}</h4>
                                        </template>

                                        <fg-input v-model="formModel.firstName" addon-left-icon="nc-icon nc-single-02" :placeholder="$t('registerPage.form.firstName.placeholder')" name="firstName"
                                                  v-validate="modelValidations.firstName"
                                                  :error="getError('firstName')"></fg-input>
                                        <fg-input v-model="formModel.lastName" addon-left-icon="nc-icon nc-circle-10" :placeholder="$t('registerPage.form.lastName.placeholder')" name="lastName"
                                                  v-validate="modelValidations.lastName"
                                                  :error="getError('lastName')"></fg-input>
                                        <fg-input v-model="formModel.email" addon-left-icon="nc-icon nc-email-85" :placeholder="$t('registerPage.form.email.placeholder')" name="email"
                                                  v-validate="modelValidations.email" :disabled="emailLocked"
                                                  :error="getError('email')"></fg-input>
                                        <fg-input v-model="formModel.password" addon-left-icon="nc-icon nc-key-25" :placeholder="$t('registerPage.form.password.placeholder')" name="password" type="password"
                                                  v-validate="modelValidations.password"
                                                  :error="getError('password')"></fg-input>
                                        <p-checkbox class="text-left" v-model="formModel.acceptTerms" name="agreement">
                                            <div>
                                                {{ $t('registerPage.form.acceptTerms.tc.text1')}} <a href="https://lazycompany.atlassian.net/wiki/spaces/LPS/pages/31457281" target="_blank">{{ $t('registerPage.form.acceptTerms.tc.linkText')}}</a>  {{ $t('registerPage.form.acceptTerms.tc.text2')}}
                                            </div>
                                            <div>
                                                {{ $t('registerPage.form.acceptTerms.pp.text')}} <a href="https://lazycompany.atlassian.net/wiki/spaces/LPS/pages/41222356/" target="_blank">{{ $t('registerPage.form.acceptTerms.pp.linkText')}}</a>
                                            </div>
                                        </p-checkbox>
                                       
                                        <div slot="footer">
                                            <p-button native-type="submit" type="info" class="" round @click.prevent="validate" :disabled="!formModel.acceptTerms">{{ $t('registerPage.btnRegister')}}</p-button>
                                        </div>
                                    </card>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <app-footer></app-footer>
                <div class="full-page-background"
                     style="background-image: url(static/img/background/lp-background.png)"></div>
            </div>
        </div>
    </div>
</template>
<script>
    import AppNavbar from './Layout/AppNavbar'
    import AppFooter from './Layout/AppFooter'
    import { Card, Checkbox, Button, InfoSection } from 'src/components/UIComponents'
    import swal from 'sweetalert2'

    export default {
        components: {
            Card,
            AppNavbar,
            AppFooter,
            InfoSection,
            [Checkbox.name]: Checkbox,
            [Button.name]: Button,
        },
        data() {
            var vueThis = this;
            return {
                emailLocked: false,
                formModel: {
                    firstName: '',
                    lastName: '',
                    email: '',
                    acceptTerms: false,
                    recaptcha: ''
                },
                modelValidations: {
                    firstName: {
                        required: true,
                        max: 200,
                    },
                    lastName: {
                        required: true,
                        max: 200,
                    },
                    email: {
                        required: true,
                        email: true,
                        max: 200,
                    },
                    password: {
                        required: true,
                        regex: vueThis.$root.config.passwordRegex,
                        max: 200,
                    },
                }
            }
        },
        methods: {
            closeMenu() {
                document.body.classList.remove('nav-open')
                document.body.classList.remove('off-canvas-sidebar')
            },
            async register() {
                let vueThis = this;
                let loader = this.$loading.show(this.$root.config.getLoadingCfg(null));
                try {
                    await this.recaptcha();
                } catch (error) {
                    vueThis.writeEx2Log(error);
                    console.error(error);
                    vueThis.formModel.recaptcha = "Err";
                }
                this.axios.post(this.$root.config.account.registrationUrl(), this.formModel)
                    .then(response => {
                        loader.hide();
                        swal.fire({
                            icon: 'success',
                            title: vueThis.$t('registerPage.success.msg'),
                            text: vueThis.$t('registerPage.success.text'),
                            showConfirmButton: true,
                            timer: vueThis.$root.config.alertTimer15,
                            timerProgressBar: true,
                        }).then((r) => { 
                            vueThis.$router.push('/login');
                        });
                    })
                    .catch(function (error) {
                        loader.hide();
                        if (error.response.status == 400) {
                            //console.log(error.response.data);
                            let respMsg = error.response.data;
                            swal.fire({
                                title: vueThis.$t('registerPage.failed'),
                                text: vueThis.$t('apiErrorCode.' + respMsg.code + '.msg'),
                                icon: vueThis.$t('apiErrorCode.' + respMsg.code + '.icon'),
                                showConfirmButton: true,
                                timer: vueThis.$root.config.alertTimer15,
                                timerProgressBar: true,
                            });
                        }
                        else {
                            vueThis.writeEx2Log(error);
                            vueThis.genericErrorAlert();
                        }
                    });
            },
            getError(fieldName) {
                let familierName = this.$t('registerPage.form.' + fieldName + '.familierName');
                let errMsg = this.errors.first(fieldName);
                if (fieldName == "password" && this.errors.items.some(c => c.field === "password" && c.rule === "regex")) {
                    errMsg = this.$t('registerPage.form.password.passwordRegexErrMsg');
                }
                if (errMsg === undefined) return errMsg;
                errMsg = errMsg.replace(fieldName, familierName);
                return errMsg;
            },
            validate() {
                this.$validator.validateAll().then(isValid => {
                    if (isValid) {
                        this.register();
                    }
                })
            },
            async recaptcha() {
                await this.$recaptchaLoaded();
                const token = await this.$recaptcha('register');
                this.formModel.recaptcha = token;
            },
        },
        beforeDestroy() {
            this.closeMenu()
        },
        created() {
            if (this.$route.query.inve != undefined) {
                this.formModel.email = this.$route.query.inve;
                this.emailLocked = true;
            }
        }
    }
</script>
<style>

</style>
