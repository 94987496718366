<template>
    <div class="login-page">
        <app-navbar></app-navbar>
        <div class="wrapper wrapper-full-page">
            <div class="full-page login-page section-image">
                <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
                <div class="content">
                    <div class="container">
                        <div class="col-lg-4 col-md-6 ml-auto mr-auto text-center">
                            <form>
                                <card type="login">
                                    
                                    
                                    <h3 slot="header" class="header text-center" v-if="enterMode=='cred'">{{ $t('loginPage.headerCred')}}</h3>
                                    <h3 slot="header" class="header text-center" v-if="enterMode=='mfa'">{{ $t('loginPage.headerMfa')}}</h3>

                                    <fg-input v-model="formModel.email" addon-left-icon="nc-icon nc-email-85" :placeholder="$t('loginPage.form.email.placeholder')" name="email" v-bind:class="{ 'd-none': enterMode=='mfa' }"
                                              v-validate="modelValidations.email" 
                                              :error="getError('email')"></fg-input>

                                    <fg-input v-model="formModel.password" addon-left-icon="nc-icon nc-key-25" :placeholder="$t('loginPage.form.password.placeholder')" name="password" type="password" v-bind:class="{ 'd-none': enterMode=='mfa' }"
                                              v-validate="modelValidations.password"
                                              :error="getError('password')"></fg-input>

                                    <router-link :to="{ name: 'ResetPwd'}" v-if="enterMode=='cred'">{{ $t('loginPage.linkResetPwd')}}</router-link>

                                    <div class="mt-2" v-if="enterMode=='cred'">
                                        {{ $t('loginPage.noAccountToRegister.text')}}
                                        <router-link :to="{ name: 'Register'}">
                                            {{ $t('loginPage.noAccountToRegister.linkHere')}}
                                        </router-link>
                                    </div>

                                    <fg-input class="mt-2" v-model="formModel.mfaCode" ref="mfaCode" addon-left-icon="nc-icon nc-mobile" :placeholder="$t('loginPage.form.mfaCode.placeholder')" name="mfaCode" type="mfaCode"
                                              v-validate="modelValidations.mfaCode" v-if="enterMode=='mfa'"
                                              :error="getError('mfaCode')"></fg-input>

                                    <div slot="footer">
                                        <p-button native-type="submit" type="warning" round block class="mb-3" @click.prevent="validate">{{ $t('loginPage.btnLogin')}}</p-button>
                                        <p-button native-type="button" type="primary" round block class="mb-3" v-if="enterMode=='mfa'" @click="cancelMFA">{{ $t('loginPage.btnCancel')}}</p-button>
                                        <p class="lp-policy" v-if="enterMode=='cred'">
                                            LazyPhish <a href="https://lazycompany.atlassian.net/wiki/spaces/LPS/pages/41222356/" target="_blank">{{ $t('loginPage.linkPP')}}</a>
                                        </p>

                                    </div>
                                </card>
                            </form>
                        </div>
                    </div>
                </div>
                <app-footer>

                </app-footer>
                <div class="full-page-background" style="background-image: url(static/img/background/lp-background.png) "></div>
            </div>
        </div>
    </div>
</template>
<script>
    import { Card, Checkbox, Button } from 'src/components/UIComponents';
    import AppNavbar from './Layout/AppNavbar'
    import AppFooter from './Layout/AppFooter'
    import swal from 'sweetalert2'


    export default {
        components: {
            Card,
            AppNavbar,
            AppFooter,
            [Checkbox.name]: Checkbox,
            [Button.name]: Button
        },
        created: async function () {
            let vueThis = this;
            let token = this.$route.query.token;
            if (token == null || token == undefined || token === '') {
                this.axios.post(this.$root.config.account.autologinUrl(), null)
                    .then(response => {
                        this.$store.commit('login', response.data);
                        if (this.redirectForNoBE()) {
                            this.$router.push({ name: "beList" });
                        }
                        else {                         
                            this.$router.push({ name: 'aDashboard' });
                        }
                    })
                    .catch(function (error) { vueThis.writeEx2Log(error); });
            }
            else {
                this.loginByToken(token);
            }
        },
        methods: {
            closeMenu() {
                document.body.classList.remove('nav-open')
                document.body.classList.remove('off-canvas-sidebar')
            },
            async recaptcha() {
                await this.$recaptchaLoaded();
                const token = await this.$recaptcha('login');
                this.formModel.recaptcha = token;
            },
            async login() {
                let vueThis = this;
                let loader = this.$loading.show(this.$root.config.getLoadingCfg(null));
                try {
                    await this.recaptcha();
                } catch (error) {
                    vueThis.writeEx2Log(error);
                    console.error(error);
                    vueThis.formModel.recaptcha = "Err";
                }

                this.axios.post(this.$root.config.account.loginUrl(), this.formModel)
                    .then(response => {
                        loader.hide();
                        let loginResp = response.data;
                        if (loginResp.mfa) {
                            vueThis.enterMode = 'mfa';
                        }
                        else {
                            this.$store.commit('login', loginResp.accountDetail);
                            if (this.redirectForNoBE()) {
                                this.$router.push({ name: "beList" });
                            }
                            else {
                                this.$router.push({ name: 'aDashboard' });
                            }
                        }
                    })
                    .catch(function (error) {
                        loader.hide();
                        if (error.response.status == 400) {
                            let respMsg = error.response.data;
                            console.log(respMsg);
                            vueThis.recaptcha();
                            swal.fire({
                                title: vueThis.$t('loginPage.failed'),
                                text: vueThis.$t('apiErrorCode.' + respMsg.code + '.msg'),
                                icon: vueThis.$t('apiErrorCode.' + respMsg.code + '.icon'),
                            });
                        }
                        else {
                            vueThis.writeEx2Log(error);
                            vueThis.genericErrorAlert();
                        }
                        
                    });
            },
            getError(fieldName) {
                let familierName = this.$t('loginPage.form.' + fieldName + '.familierName');
                var errMsg = this.errors.first(fieldName);
                if (errMsg === undefined) return errMsg;
                errMsg = errMsg.replace(fieldName, familierName);
                return errMsg;
            },
            validate() {
                this.$validator.validateAll().then(isValid => {
                    if (isValid) {
                        this.login();
                    }
                })
            },
            cancelMFA() {
                document.location.reload();
            },
            loginByToken(token) {
                if (token == undefined || token === '')
                    return;
                let vueThis = this;
                let loader = this.$loading.show(this.$root.config.getLoadingCfg(null));
                this.axios.post(this.$root.config.account.loginForDemoUrl() + "?token="+token, null)
                    .then(response => {
                        loader.hide();
                        let loginResp = response.data;
                        this.$store.commit('login', loginResp.accountDetail);
                        this.$router.push({ name: 'aDashboard' });                            
                    })
                    .catch(function (error) {
                        loader.hide();
                        if (error.response.status == 400) {
                            let respMsg = error.response.data;
                            swal.fire({
                                title: vueThis.$t('loginPage.failed'),
                                text: vueThis.$t('apiErrorCode.' + respMsg.code + '.msg'),
                                icon: vueThis.$t('apiErrorCode.' + respMsg.code + '.icon'),
                            });
                        }
                        else {
                            vueThis.writeEx2Log(error);
                            vueThis.genericErrorAlert();                         
                        }
                    });
            },
            redirectForNoBE() {
                return !this.$store.getters.userState.billingEntities.some(e => 1);                
            },
        },
        data() {
            var vueThis = this;
            return {
                formModel: {
                    email: '',
                    password: '',
                    mfaCode: '',
                },
                enterMode: 'cred',
                modelValidations: {
                    email: {
                        required: true,
                        email: true,
                        max: 200,
                    },
                    password: {
                        required: true,
                        min: 8,
                        max: 200,
                    },
                    mfaCode: {
                        required: true,
                    },
                }
            }
        },
        beforeDestroy() {
            this.closeMenu();
        }
    }
</script>
<style>

</style>
