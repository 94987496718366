<template>
  <footer class="footer">
      <div class="container-fluid">
          <div class="copyright pull-right mr-5">
              <span>{{$t("appFooter.copyright", [new Date().getFullYear()])}} LazyPhish v.{{$root.config.appVersion}} (APIv.{{serverVersion}})</span>
          </div>
      </div>
  </footer>
</template>
<script>
    export default {
        computed: {
            serverVersion() {
                let sv = this.$store.getters.appState.serverVersion;
                if (sv == null || sv == undefined) return "?.?.?.?";
                return sv;
            },
        }
    }
</script>
<style>

</style>
