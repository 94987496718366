import Vue from 'vue'
import './pollyfills'
import VueRouter from 'vue-router'
import VueRouterPrefetch from 'vue-router-prefetch'
import VueNotify from 'vue-notifyjs'

import VeeValidate, { Validator } from 'vee-validate'
import veeValidEN from 'vee-validate/dist/locale/en';
import veeValidCS from 'vee-validate/dist/locale/cs';
import veeValidDE from 'vee-validate/dist/locale/de';
import veeValidSK from 'vee-validate/dist/locale/sk';


import VueCookies from 'vue-cookies'
import axios from 'axios'
import VueAxios from 'vue-axios'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import VueMoment from 'vue-moment'
import VueAppInsights from 'vue-application-insights'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import infiniteScroll from 'vue-infinite-scroll'

import App from './App.vue'

// Plugins
import GlobalComponents from './globalComponents'
import GlobalDirectives from './globalDirectives'
import SideBar from './components/UIComponents/SidebarPlugin'
import initProgress from './progressbar';
import loadingOverlay from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import swal from 'sweetalert2'

// v-viewer
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'


// localization
import countryFlag from 'vue-country-flag'
Vue.component('country-flag', countryFlag)
import i18n, { langMessages } from './lang'
//console.log(langMessages);

// router setup
import routes from './routes/routes'

// storage
import store from './storage/storage'

// config
import config from './GlobalConfig'

// library imports
import './assets/sass/paper-dashboard.scss'
import './assets/sass/demo.scss'


// validation
veeValidEN.messages.email = langMessages.en.validations.messages.email;
veeValidCS.messages.email = langMessages.cs.validations.messages.email;
veeValidDE.messages.email = langMessages.de.validations.messages.email;
veeValidSK.messages.email = langMessages.sk.validations.messages.email;

VeeValidate.Validator.localize('en');

Validator.extend('taxnumber', {
        getMessage: (field, [args]) => {
            return i18n.t('validations.messages.taxnumber');
        },
        validate: async (value, [args]) => {
            if (args === 'eu') {
                let result = await axios.post(config.billingEntity.isVATNumberValidUrl(), null, { params: { taxnumber: value } })
                    .then(response => {
                        return response.data;
                    }).catch(function (error) {
                        vueThis.writeEx2Log(error);
                        vueThis.genericErrorAlert();
                        return false;
                    });
                return result;
            }
            else {
                return true;
            }
        },
    },
    {
        hasTarget: true
    });


import sidebarLinks from './sidebarLinks'
import './registerServiceWorker'
import { Tools } from './lpLibrary'
// plugin setup
Vue.use(VueRouter)
Vue.use(VueRouterPrefetch)
Vue.use(GlobalDirectives)
Vue.use(GlobalComponents)
Vue.use(VueNotify)
Vue.use(SideBar, { sidebarLinks: sidebarLinks })
Vue.use(VeeValidate, {
    i18nRootKey: 'validations',
    i18n,
    dictionary: {
        en: veeValidEN,
        cs: veeValidCS,
        de: veeValidDE,
        sk: veeValidSK
    }
})
Vue.use(loadingOverlay)
Vue.use(VueAxios, axios)
Vue.use(VueMoment)
Vue.use(VueCookies, { expires: '1d' })
Vue.use(VueViewer)
Vue.use(infiniteScroll)

Vue.use(VueReCaptcha, {
    siteKey: config.reCaptchaSiteKey,
    loaderOptions: {
        autoHideBadge: true,
    }
})
locale.use(lang)
Vue.mixin({
    methods: {
        genericErrorAlert() {
            swal.fire({
                icon: 'error',
                title: i18n.t('general.errorMsg'),
                showConfirmButton: true,
                timer: config.alertTimer15,
                timerProgressBar: true,
            });
        },
        isValidEmail(email) {
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },
        NumberFormat(x) {
            return Tools.NumberFormat(x);
        },
        writeEx2Log(ex) {
            console.log(ex);
            this.$appInsights.trackException({ exception: ex });
        }
    }
})


// configure router
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes, // short for routes: routes
    linkActiveClass: 'active',
    scrollBehavior: (to) => {
        if (to.hash) {
            return { selector: to.hash }
        } else {
            return { x: 0, y: 0 }
        }
    }
})

initProgress(router);

Vue.use(VueAppInsights, {
    id: config.appInsightsID,
    trackInitialPageView: false,
    router
})

axios.defaults.headers = {
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0',
};
axios.defaults.withCredentials = true;
axios.interceptors.response.use(
    function (response) {
        //console.log(response);
        let serverVersion = response.headers['lp-version'];
        //console.log(serverVersion);
        vueApp.$store.commit('updateServerVersion', serverVersion);
        return response;
    },
    function (error) {
        //console.log(error);
        let serverVersion = error.response.headers['lp-version'];
        //console.log(serverVersion);
        if (serverVersion != undefined) {
            vueApp.$store.commit('updateServerVersion', serverVersion);
        }
        if (error != null && error.response != null && error.response.status == 400 && error.response.data.code == 100207) {
            swal.fire({
                icon: 'error',
                title: i18n.t('general.accessDenied.msg'),
                text: i18n.t('general.accessDenied.text'),
                showConfirmButton: true,
                timer: config.alertTimer15,
                timerProgressBar: true,
            });
            return new Promise(() => { });
        }
        if (error != null && error.response != null && error.response.status == 401) {
            vueApp.$router.push('/login');
            swal.fire({
                icon: 'info',
                title: i18n.t('general.sessionExpired.msg'),
                text: i18n.t('general.sessionExpired.text'),
                showConfirmButton: true,
                timer: config.alertTimer15,
                timerProgressBar: true,
            });
            return new Promise(() => { });
        }
        if (error.isAxiosError)
            return Promise.reject(error);

        return error;
    });

//-----------------------------
String.prototype.format = function () {
    var a = this;
    for (var k in arguments) {
        a = a.replace("{" + k + "}", arguments[k])
    }
    return a
}
Array.prototype.unique = function () {
    return Array.from(new Set(this));
}

//--------------------------------

Vue.config.errorHandler = (err, vm, info) => {
    console.error(err, vm, info);
    vueApp.$appInsights.trackException({ exception: new Error(`Runtime exp: msg: '${err}', source: '${info}'`) });
}

/* eslint-disable no-new */
const vueApp = new Vue({
    //el: '#app',
    render: h => h(App),
    router,
    store,
    i18n,
    data: {
        config: config,
        cultureCode: 'en',
    },
    created() {
        let cCC = this.$cookies.get("ui-cc");
        if (cCC == null)
            cCC = this.cultureCode;

        this.cultureCode = cCC;
        this.$i18n.locale = cCC;
        this.$moment.locale(cCC);
    },
    watch: {
        cultureCode: function (val) {
            this.$i18n.locale = val;
            this.$moment.locale(val);
            VeeValidate.Validator.localize(val);
            this.$cookies.set("ui-cc", val, "10y", null, null, true); 
        }
    }
}).$mount('#app')

vueApp.$appInsights.trackPageView();
