<template>
    <div class="card card-control">
        <div class="card-header">
            <p class="card-title">{{title}}</p>
        </div>
        <div class="card-body" :class="{'mt-3' : useTopMargin}">
            <div class="row h-100 align-items-center">
                <div class="col-12">
                    <slot name="icon">
                        <div class="icon-big text-center" :class="`text-${type}`">
                            <i :class="icon"></i>
                        </div>
                    </slot>
                </div>
                <div class="col-12 mt-3 px-5" v-if="showSmallTitle">
                    <p class="card-category text-center" :class="extColorSmallTitle" :style="{ fontSize: fontSizeSmallTitle + '%' }">{{smallTitle}}</p>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <hr v-if="showSeparator"/>
            <slot name="footer">

            </slot>
        </div>        
    </div>
</template>

<script>
    export default {
        name: 'control-card',
        props: {
            type: {
                type: String,
                description: 'Card text type (primary|info|danger|default|warning|success)',
                default: 'default'
            },
            title: {
                type: String,
                description: 'Card title'
            },
            smallTitle: {
                type: String,
                description: 'Card small title'
            },
            icon: {
                type: String,
                description: 'Card icon'
            },
            showSeparator: {
                type: Boolean,
                default: true,
            },
            coloredSmallTitle: {
                type: Boolean,
                default: false,
            },
            sizeSmallTitle: {
                type: Number,
                default: 120
            }
        },
        computed: {
            extColorSmallTitle() {
                if (this.coloredSmallTitle) {
                    return `text-${this.type}`;
                }
                return "";
            },
            showSmallTitle() {
                return this.smallTitle != undefined && this.smallTitle != null && this.smallTitle != "";
            },
            useTopMargin() {
                return this.title == undefined || this.title == null || this.title == "";
            },
            fontSizeSmallTitle() {
                return this.sizeSmallTitle;
            }
        }
    }
</script>

<style scoped>

</style>