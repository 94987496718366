<template>
    <div class="content">

        <transition name="fade" mode="out-in">
            <!-- your content here -->
            <router-view ref="content"></router-view>
        </transition>
    </div>
</template>
<script>
    export default {
        methods: {

        },
    }
</script>
<style>
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity .15s
    }

    .fade-enter,
    .fade-leave-to
    /* .fade-leave-active in <2.1.8 */ {
        opacity: 0
    }
</style>
