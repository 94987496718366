<template>
    <div class="sidebar"
         :data-color="backgroundColor"
         :data-active-color="activeColor">
        <div class="logo">
            <div class="d-flex justify-content-center">
                <!--<a class="simple-text logo-mini" :href="publicWebUrl" aria-label="sidebar mini logo">
                    <div class="logo-img">
                        <img :src="logo" alt="">
                    </div>
                </a>-->
                <router-link class="simple-text logo-mini" :to="{ name: 'aDashboard'}" aria-label="sidebar mini logo">
                    <div class="logo-img">
                        <img :src="logo" alt="">
                    </div>
                </router-link>
            </div>
        </div>
        <div class="sidebar-wrapper" ref="sidebarScrollArea">
            <slot>

            </slot>
            <div class="entity-menu" data-v-step="sb-entity-menu">
                <slot name="maincontent"></slot>
                <ul class="nav">
                    <slot name="links">
                        <sidebar-item v-for="(link, index) in links"
                                      :key="link.name + index"
                                      :link="link" :tourId="link.tourId">

                            <sidebar-item v-for="(subLink, index) in link.children"
                                          :key="subLink.name + index"
                                          :link="subLink" :tourId="link.tourId">
                            </sidebar-item>
                        </sidebar-item>
                    </slot>

                </ul>
            </div>          
        </div>
    </div>
</template>
<script>
    import 'perfect-scrollbar/dist/css/perfect-scrollbar.css'
    export default {
        props: {
            title: {
                type: String,
                default: 'Sidebar',
                description: 'Sidebar title'
            },
            backgroundColor: {
                type: String,
                default: 'black',
                validator: (value) => {
                    let acceptedValues = ['white', 'brown', 'black']
                    return acceptedValues.indexOf(value) !== -1
                },
                description: 'Sidebar background color (white|brown|black)'
            },
            activeColor: {
                type: String,
                default: 'success',
                validator: (value) => {
                    let acceptedValues = ['primary', 'info', 'success', 'warning', 'danger']
                    return acceptedValues.indexOf(value) !== -1
                },
                description: 'Sidebar active text color (primary|info|success|warning|danger)'
            },
            logo: {
                type: String,
                default: '/static/img/lp-logo-menu-white.png',
                description: 'Sidebar Logo'
            },
            sidebarLinks: {
                type: Array,
                default: () => [],
                description: 'Sidebar links. Can be also provided as children components (sidebar-item)'
            },
            autoClose: {
                type: Boolean,
                default: true
            }
        },
        provide() {
            return {
                autoClose: this.autoClose
            }
        },
        methods: {
            async initScrollBarAsync() {
                let isWindows = navigator.platform.startsWith('Win');
                if (!isWindows) {
                    return;
                }
                const PerfectScroll = await import('perfect-scrollbar')
                PerfectScroll.initialize(this.$refs.sidebarScrollArea)                
            },
            minimizeSidebar() {
                this.$sidebar.toggleMinimize()
            },
        },
        mounted() {
            this.initScrollBarAsync()
        },
        beforeDestroy() {
            if (this.$sidebar.showSidebar) {
                this.$sidebar.showSidebar = false
            }
        },
        computed: {
            publicWebUrl() {
                return this.$root.config.getPublicWebUrl;
            },
            links() {
                let sEnt = this.$store.getters.appState.entity;
                if (sEnt != undefined) {
                    if (sEnt.manualControl) {
                        return this.sidebarLinks;
                    }
                }
                return this.sidebarLinks.filter(c => c.show.length == 0);
            }
        },
    }

</script>
<style>

    @media (min-width: 992px) {
        .navbar-search-form-mobile,
        .nav-mobile-menu {
            display: none;
        }
    }
</style>
