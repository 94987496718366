<template>
    <footer class="footer footer-black footer-white">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-sm-6 text-center text-sm-left">
                    <p class="lp-copyright">{{$t("appFooter.copyright", [new Date().getFullYear()])}}</p>
                    <p class="lp-version">LazyPhish v.{{$root.config.appVersion}} (APIv.{{serverVersion}})</p>
                </div>
                <div class="col-12 col-sm-6 text-center text-sm-right">
                    <div class="copyright">
                        <p class="recaptcha-legacy">
                            {{$t("appFooter.legacy1")}}
                            <a href="https://policies.google.com/privacy" target="_blank"> {{$t("appFooter.legacy2")}}</a>
                            {{$t("appFooter.legacy3")}}
                            <a href="https://policies.google.com/terms" target="_blank"> {{$t("appFooter.legacy4")}}</a>
                            {{$t("appFooter.legacy5")}}.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
    export default {
        name: 'main-footer',
        computed: {
            serverVersion() {
                let sv = this.$store.getters.appState.serverVersion;
                if (sv == null || sv == undefined) return "?.?.?.?";
                return sv;
            },
        }
    }
</script>
<style>
</style>
