import Vue from 'vue'
import Vuex from 'vuex'
import config from '../GlobalConfig'
import axios from 'axios'
import { RoleEnum } from '../lpLibrary'


Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        user: {
            loggedIn: false,
            fullName: '',
            accountHID: '',
            isSuperAdmin: false,
            isDemo: false,
            entities: [],
            billingEntities: [],
        },
        app: {
            entity: null,
            serverVersion: null,
        },
        campaignList: {
            campaignsFilter: {
                status: '',
            },
        },
        beListTable: {
            expandRows: [],
        },
    },
    getters: {
        userState(state) {
            return state.user
        },
        appState(state) {
            return state.app;
        },
        campaignListState(state) {
            return state.campaignList;
        },
        beListTableState(state) {
            return state.beListTable;
        },
    },
    mutations: {
        login(state, accountInfo) {
            if (!state.user.isSuperAdmin) {
                state.user.isSuperAdmin = accountInfo.isBillingEntitySuperAdmin && accountInfo.isEntitySuperAdmin;
            }
            if (!state.user.isSuperAdmin) {
                state.user.accountHID = accountInfo.accountHID;
            }
            
            state.user.loggedIn = true;
            state.user.fullName = accountInfo.firstName + " " + accountInfo.lastName;
            state.user.entities = accountInfo.entities;
            state.user.billingEntities = accountInfo.billingEntities;
            if (state.app.entity != null && state.user.entities.find(c => c.id == state.app.entity.id) == undefined) {
                state.app.entity = null;
            }

            state.user.isDemo = accountInfo.entities.some(c => c.role === RoleEnum.DemoName);

        },
        logout(state) {
            state.user.loggedIn = false;
            state.user.fullName = '';
            state.user.accountHID = '';
            state.user.isSuperAdmin = false;
            state.user.entities = [];
            state.user.billingEntities = [];
            state.app.entity = null;
        },
        selectEntity(state, entity) {
            state.app.entity = entity;
        },
        updateUserName(state, fullname) {
            state.user.fullName = fullname;
        },
        updateAccountHID(state, accountHID) {
            state.user.accountHID = accountHID;
        },
        updateCampaignList(state, campaignsFilter) {
            state.campaignList.campaignsFilter = campaignsFilter;
        },
        updateBeListTableExpRows(state, expandRows) {
            state.beListTable.expandRows = expandRows;
        },
        updateServerVersion(state, version) {
            if (state.app.serverVersion != version && version != undefined && version != null) {
                state.app.serverVersion = version;
            }
        },
    },
    actions: {
        updateUser({ commit, state }) {
            if (state.user.accountHID == null || state.user.accountHID === '') {
                return;
            }
            axios.post(config.account.getAccountDetailUrl(), null, { params: { accountHID: state.user.accountHID } })
                .then(response => {
                    let data = response.data;
                    commit('login', data);
                    if (state.app.entity != null || state.app.entity != undefined) {
                        state.app.entity = data.entities.find(c => c.hid == state.app.entity.hid);
                    }                    
                }).catch(function (error) { console.log(error) });
        }
    }
})

export default store;