import Vue from "vue"
import VueI18n from "vue-i18n"
import axios from 'axios'
import config from '../GlobalConfig'
import defaults from 'defaults';

Vue.use(VueI18n);

export const langMessages = {}

const extResources = [
    { lang: "en", url: config.storageBaseUrl + "/public/spa/en.json?ts=" + Date.now() },
    { lang: "cs", url: config.storageBaseUrl + "/public/spa/cs.json?ts=" + Date.now() },
    { lang: "de", url: config.storageBaseUrl + "/public/spa/de.json?ts=" + Date.now() },
    { lang: "sk", url: config.storageBaseUrl + "/public/spa/sk.json?ts=" + Date.now() }

];


function loadMessages() {
    const locales = require.context(".",true,/[A-Za-z0-9-_,\s]+\.json$/i);
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            langMessages[locale] = locales(key);
        }
    });

    extResources.forEach(lang => {
        axios.get(lang.url)
            .then(function (response) {
                //console.log(response, response.data);
                if (response.status == 200) {
                    langMessages[lang.lang] = defaults(response.data, langMessages[lang.lang]);
                }
            })
            .catch(function (ex) {
                console.log("External resource is not available", lang);
            })
    });

    return langMessages;
}



// VueI18n instance
const i18n = new VueI18n({
    locale: 'en',
    messages: loadMessages()
});

// Hot updates
if (module.hot) {
    module.hot.accept(['./en'], function () {
        i18n.setLocaleMessage('en', require('./en').default)
        i18n.setLocaleMessage('cs', require('./cs').default)
        i18n.setLocaleMessage('de', require('./de').default)
        i18n.setLocaleMessage('sk', require('./sk').default)
        // Or the following hot updates via $i18n property
        // app.$i18n.setLocaleMessage('en', require('./en').default)
        // app.$i18n.setLocaleMessage('ja', require('./ja').default)
    });
}

export default i18n;