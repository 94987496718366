import multiguard from 'vue-router-multiguard';

import DashboardLayout from '../components/Dashboard/Layout/DashboardLayout.vue'
// GeneralViews
import NotFound from '../components/GeneralViews/NotFoundPage.vue'
// Dashboard pages
const HelpSupport = () => import('src/components/Dashboard/Views/HelpSupport.vue')


// Entiry pages
const ELazyAIView = () => import('src/components/Dashboard/Views/Entity/LazyAI.vue')
const EHVGroupsListView = () => import('src/components/Dashboard/Views/Entity/HVGroupsList.vue')
const EHVGroupView = () => import('src/components/Dashboard/Views/Entity/HVGroup.vue')
const ECampaignsListView = () => import('src/components/Dashboard/Views/Entity/CampaignsList.vue')
const ECampaignView = () => import('src/components/Dashboard/Views/Entity/Campaign.vue')
const NewCampaignView = () => import('src/components/Dashboard/Views/Entity/NewCampaign.vue')
const EReportView = () => import('src/components/Dashboard/Views/Entity/EntityReport.vue')
const EHVGroupReportView = () => import('src/components/Dashboard/Views/Entity/HVGroupReport.vue')
const EHVReportView = () => import('src/components/Dashboard/Views/Entity/HVReport.vue')
const ETrainingView = () => import('src/components/Dashboard/Views/Entity/Training.vue')
const ESetupView = () => import('src/components/Dashboard/Views/Entity/Setup.vue')

// account pages
const AccountDashboardView = () => import('src/components/Dashboard/Views/Account/Dashboard.vue')
const MyAccountView = () => import('src/components/Dashboard/Views/Account/MyAccount.vue')
const BillingEntityListView = () => import('src/components/Dashboard/Views/Account/BillingEntityList.vue')

// camp. block groups
const CampaignBlockGroupListView = () => import('src/components/Dashboard/Views/CampaignBlockGroups/CampaignBlockGroupList.vue')
const CampaignBlockGroupView = () => import('src/components/Dashboard/Views/CampaignBlockGroups/CampaignBlockGroup.vue')
const NewCampaignBlockGroupView = () => import('src/components/Dashboard/Views/CampaignBlockGroups/NewCampaignBlockGroup.vue')

// camp. blocks
const CampaignBlockListView = () => import('src/components/Dashboard/Views/CampaignBlocks/CampaignBlockList.vue')

// BE
const SubscriptionsView = () => import('src/components/Dashboard/Views/BillingEntity/Subscriptions.vue')
const BeDetailView = () => import('src/components/Dashboard/Views/BillingEntity/BeDetail.vue')
const BeRoleMgmtView = () => import('src/components/Dashboard/Views/BillingEntity/BeRoleMgmt.vue')
const EntityRoleMgmtView = () => import('src/components/Dashboard/Views/BillingEntity/EntityRoleMgmt.vue')
const CreateBeView = () => import('src/components/Dashboard/Views/BillingEntity/CreateBe.vue')


// Middlewares
import { routeGuardAuth, routeGuardPermission } from './guards/auth'


// Pages
import LoginPage from 'src/components/Pages/Login.vue'
import RegisterPage from 'src/components/Pages/Register.vue'
import ResetPwdPage from 'src/components/Pages/ResetPwd.vue'
import ChangePwdPage from 'src/components/Pages/ChangePwd.vue'
import PaymentResultPage from 'src/components/Pages/PaymentResult.vue'

import ValidateDomainPage from 'src/components/Pages/ValidateDomain.vue'
import ValidateEmailPage from 'src/components/Pages/ValidateEmail.vue'



let loginPage = {
    path: '/login',
    name: 'Login',
    component: LoginPage
}

let registerPage = {
    path: '/register',
    name: 'Register',
    component: RegisterPage
}

let resetPwdPage = {
    path: '/resetpwd',
    name: 'ResetPwd',
    component: ResetPwdPage
}

let changePwdPage = {
    path: '/changepwd',
    name: 'ChangePwd',
    component: ChangePwdPage
}

let paymentResultPage = {
    path: '/paymentresult',
    name: 'PaymentResult',
    component: PaymentResultPage
}

let validateDomainPage = {
    path: '/validateDomain',
    name: 'ValidateDomain',
    component: ValidateDomainPage
}

let validateEmailPage = {
    path: '/validateEmail',
    name: 'ValidateEmail',
    component: ValidateEmailPage
}

const routes = [
    { path: '', component: LoginPage, name: 'HomePage' },
    loginPage,
    registerPage,
    resetPwdPage,
    changePwdPage,
    paymentResultPage,
    validateEmailPage,
    validateDomainPage,
    {
        path: '/account',
        name: 'account',
        component: DashboardLayout,
        children: [
            {
                path: 'dashboard',
                name: 'aDashboard',
                component: AccountDashboardView,
                beforeEnter: multiguard([routeGuardPermission]),
            },
            {
                path: 'myaccount',
                name: 'MyAccount',
                component: MyAccountView,
                beforeEnter: multiguard([routeGuardPermission]),
            },
            {
                path: 'myorgs',
                name: 'beList',
                component: BillingEntityListView,
                beforeEnter: multiguard([routeGuardPermission]),
            },
            {
                path: 'myorgs/:behid/subsctiprions',
                name: 'beSubs',
                component: SubscriptionsView,
                beforeEnter: multiguard([routeGuardPermission]),
            },
            {
                path: 'myorgs/create',
                name: 'beCreate',
                component: CreateBeView,
                beforeEnter: multiguard([routeGuardPermission]),
            },
            {
                path: 'myorgs/:behid/detail',
                name: 'beDetails',
                component: BeDetailView,
                beforeEnter: multiguard([routeGuardPermission]),
            },
            {
                path: 'myorgs/:behid/access',
                name: 'beRoleMgmt',
                component: BeRoleMgmtView,
                beforeEnter: multiguard([routeGuardPermission]),
            },
            {
                path: 'myorgs/:behid/entityaccess/:ehid',
                name: 'eRoleMgmt',
                component: EntityRoleMgmtView,
                beforeEnter: multiguard([routeGuardPermission]),
            },
        ],
        beforeEnter: multiguard([routeGuardAuth]),
    },
    {
        path: '/',
        component: DashboardLayout,
        name:'general',
        children: [
            {
                path: 'helpsupport',
                name: 'HelpSupport',
                component: HelpSupport,
            },
        ],
        beforeEnter: multiguard([routeGuardAuth]),
    },
    {
        path: '/entity/:ehid',
        name: 'entity',
        component: DashboardLayout,
        children: [
            {
                path: 'hvs/groups',
                name: 'eHVGroupsList',
                component: EHVGroupsListView,
                beforeEnter: multiguard([routeGuardPermission]),
            },
            {
                path: 'hvs/groups/:ghid',
                name: 'eHVGroup',
                component: EHVGroupView,
                beforeEnter: multiguard([routeGuardPermission]),
            },
            {
                path: 'campaigntemplates',
                name: 'eCampaignBlockGroupList',
                component: CampaignBlockGroupListView,
                beforeEnter: multiguard([routeGuardPermission]),
            },
            {
                path: 'campaigntemplates/newtemplate',
                name: 'newCampaignBlockGroup',
                component: NewCampaignBlockGroupView,
                beforeEnter: multiguard([routeGuardPermission]),
            },
            {
                path: 'campaigntemplates/:cbhid',
                name: 'eCampaignBlockGroup',
                component: CampaignBlockGroupView,
                beforeEnter: multiguard([routeGuardPermission]),
            },
            {
                path: 'campaignblocks',
                name: 'eCampaignBlockList',
                component: CampaignBlockListView,
                beforeEnter: multiguard([routeGuardPermission]),
            },
            {
                path: 'campaigns',
                name: 'eCampaignsList',
                component: ECampaignsListView,
                beforeEnter: multiguard([routeGuardPermission]),
            },
            {
                path: 'campaigns/newcampaign',
                name: 'newCampaign',
                component: NewCampaignView,
                beforeEnter: multiguard([routeGuardPermission]),
            },
            {
                path: 'campaigns/:chid',
                name: 'eCampaign',
                component: ECampaignView,
                beforeEnter: multiguard([routeGuardPermission]),
            },
            {
                path: 'report',
                name: 'eReport',
                component: EReportView,
                beforeEnter: multiguard([routeGuardPermission]),
            },
            {
                path: 'report/groups/:ghid',
                name: 'eHVGroupReport',
                component: EHVGroupReportView,
                beforeEnter: multiguard([routeGuardPermission]),
            },
            {
                path: 'report/hvs/:hvhid',
                name: 'eHVReport',
                component: EHVReportView,
                beforeEnter: multiguard([routeGuardPermission]),
            },
            {
                path: 'lazyai',
                name: 'eLazyAI',
                component: ELazyAIView,
                beforeEnter: multiguard([routeGuardPermission]),
            },
            {
                path: 'training',
                name: 'eTraining',
                component: ETrainingView,
                beforeEnter: multiguard([routeGuardPermission]),
            },
            {
                path: 'setup',
                name: 'eSetup',
                component: ESetupView,
                beforeEnter: multiguard([routeGuardPermission]),
            },

        ],
        beforeEnter: multiguard([routeGuardAuth]),
    },
    { path: '*', component: NotFound, name: 'NotFound' },
];

export default routes
