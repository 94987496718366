/* eslint-disable no-console */

// delete registred service worker
try {
    if (navigator != undefined && navigator.serviceWorker != undefined) {
        navigator.serviceWorker.getRegistrations().then(registrations => {
            try {
                for (const registration of registrations) {
                    if (registration != undefined) {
                        registration.unregister();
                    }
                }
            }
            catch (error) {
                console.error(error);
            }
        });
    }
}
catch (error) {
    console.error(error);
}
//import swal from 'sweetalert2'
//import i18n from './lang'

//import { register } from 'register-service-worker'
////let swRefreshing = false;
////let downloadingNewContent = false;
//let isEmailValidation = true;
//if (process.env.NODE_ENV === 'production'){
//    register(`${process.env.BASE_URL}service-worker.js`, {
//        registrationOptions: {
//            scope: process.env.BASE_URL,
//        },
//        ready(reg) {
//            isEmailValidation = window.location.pathname === "/validateEmail";
//            console.log(
//                'App is being served from cache by a service worker.\n' +
//                'For more details, visit https://goo.gl/AFskqB'
//            );
//            //if (downloadingNewContent) {
//            //    downloadingNewContent = false;
//            //    window.location.reload(true);
//            //}
//            //Promise.resolve().then(() => { window.location.reload(true); });
//        },
//        registered(reg) {
//            console.log('Service worker has been registered.');
//            reg.update();
//        },
//        cached() {
//            console.log('Content has been cached for offline use.');
//            window.location.reload(true);
//        },
//        updatefound() {
//            console.log('New content is downloading.');
//            //downloadingNewContent = true;
//            if (!isEmailValidation) {
//                swal.fire({
//                    icon: 'info',
//                    title: i18n.t('general.modalUpdateTitle'),
//                    showConfirmButton: false,
//                    allowOutsideClick: false,
//                });
//            }
//        },
//        updated() {
//            //if (swRefreshing) return;
//            //swRefreshing = true;
//            console.log('New content is available; please refresh.');
//            console.log(new Date());
//            //downloadingNewContent = false;
//            if (!isEmailValidation) {
//                setTimeout(function () {
//                    console.log('Force refresh');
//                    console.log(new Date());
//                    window.location.reload(true);
//                }, 3000);
//            }
//        },
//        offline() {
//            console.log('No internet connection found. App is running in offline mode.')
//        },
//        error(error) {
//            console.error('Error during service worker registration:', error)
//        }
//    })
//}
