<template>
    <div class="user">
        <div class="photo d-flex justify-content-center align-items-center mt-2">
            <i class="fa-regular fa-circle-user fa-2x" aria-hidden="true"></i>
        </div>
        <div class="info" data-v-step="sb-account-menu">
            <a data-toggle="collapse" :aria-expanded="!isClosed" @click="toggleMenu" href="#">
                <span>
                    <router-link class="user-name" :to="{ name: 'MyAccount'}" :class="{active: isMyAccountActive}">
                        {{fullName}}
                    </router-link>
                    <b class="caret"></b>
                </span>
            </a>
            <div class="clearfix"></div>
            <div>
                <collapse-transition>
                    <ul class="nav nav-menu" v-show="!isClosed">
                        <li :class="{active: isMyAccountActive}" data-v-step="sb-account-menu-myaccount">
                            <router-link :to="{ name: 'MyAccount'}" :class="{active: isMyAccountActive}">
                                <span>
                                    <span class="sidebar-mini-icon">
                                        <!--<span class="fa-solid fa-id-badge"></span>-->
                                        <span class="fa-solid fa-user-tie"></span>
                                    </span>
                                    <span class="sidebar-normal"> {{ $t('userMenu.myAccount.label')}}</span>
                                </span>
                            </router-link>
                        </li>
                        <li :class="{active: isMyOrgsActive}" data-v-step="sb-account-menu-myorg">
                            <router-link :to="{ name: 'beList'}" :class="{active: isMyOrgsActive}">
                                <span>
                                    <span class="sidebar-mini-icon">
                                        <span class="fa-solid fa-building"></span>
                                    </span>
                                    <span class="sidebar-normal">{{ $t('userMenu.myBE.label')}}</span>
                                </span>
                            </router-link>
                        </li>
                        <li data-v-step="sb-account-menu-logout">
                            <a href="#" @click="logout">
                                <span class="sidebar-mini-icon">
                                    <span class="fa-solid fa-right-from-bracket"></span>
                                </span>
                                <span class="sidebar-normal">{{ $t('userMenu.logout.label')}}</span>
                            </a>
                        </li>
                    </ul>
                </collapse-transition>
            </div>
        </div>
    </div>
</template>
<script>
    import { CollapseTransition } from 'vue2-transitions'
    import swal from 'sweetalert2'
    var unsubFnc = null;

    export default {
        components: {
            CollapseTransition
        },
        data() {
            return {
                isClosed: true,
                fullName: "",
            }
        },
        methods: {
            toggleMenu() {
                this.isClosed = !this.isClosed
            },
            controlExpandMenu(value) {
                this.isClosed = !value;
            },
            logout() {
                let vueThis = this;
                this.axios.post(this.$root.config.account.logoutUrl(), null)
                    .then(response => {
                        vueThis.$store.commit('logout');
                        vueThis.$router.push({ name: 'Login' });
                    })
                    .catch(error => {
                        if (error.response.status == 400) {
                            console.log(error.response.data);
                            swal.fire({
                                icon: 'warning',
                                title: vueThis.$t('userMenu.logout.error'),
                                showConfirmButton: true,
                                timer: vueThis.$root.config.alertTimer15,
                                timerProgressBar: true,
                            });
                        }
                        else {
                            vueThis.writeEx2Log(error);
                            vueThis.genericErrorAlert();
                        }
                    });
            },
            checkToggleMenu() {
                if (this.isMyAccountActive || this.isMyOrgsActive) {
                    this.isClosed = false;
                }
            }
        },
        created: function () {
            this.fullName = this.$store.getters.userState.fullName;
            unsubFnc = this.$store.subscribe((mutation, state) => {
                if (mutation.type === 'updateUserName') {
                    this.fullName = this.$store.getters.userState.fullName;
                }
            });

        },
        beforeDestroy() {
            unsubFnc();
        },
        mounted() {
            this.checkToggleMenu();
        },
        computed: {
            isMyAccountActive() {
                if (this.$route) {
                    return this.$route.path.startsWith("/account/myaccount");
                }
                return false;
            },
            isMyOrgsActive() {
                if (this.$route) {
                    return this.$route.path.startsWith("/account/myorgs");
                }
                return false;
            },
        },
    }
</script>
<style scoped>

    .nav.nav-menu {
        margin-top: 0;
    }
</style>
