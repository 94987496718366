import axios from 'axios'
import config from '@/GlobalConfig'
import store from '@/storage/storage'

export const routeGuardAuth = (to, from, next) => {
    //console.log("AUTH-MW");
    //console.log(to, from);
    if (!store.getters.userState.loggedIn) {
        axios.post(config.account.autologinUrl(), null)
            .then(response => {
                let accountDetails = response.data;
                store.commit('login', accountDetails);
                next();
            })
            .catch(function (error) {
                console.log(error);
                next({ name: 'Login', });
            });
    }
    else {
        next();
    }
}

export const routeGuardPermission = (to, from, next) => {
    nextAlgo(to, from, next);
}

function nextAlgo(to, from, next) {
    let behid = to.params.behid;
    let ehid = to.params.ehid;
    //console.log(to);
    let routeSection = to.matched[0].name;
    //console.log(routeSection);
    let user = store.getters.userState;
    switch (routeSection) {
        case 'entity':
            if (ehid != undefined) {
                let entity = user.entities.find(c => c.hid == ehid);
                if (entity == undefined) {
                    next({ name: 'NotFound' });
                    break;
                }
                if (entity.subscriptionExpiration == null) { // entita nema aktivni sub, presmerovani na dashboard
                    next({ name: 'aDashboard', });
                }
                else if (entity != undefined && entity != null) {
                    store.commit('selectEntity', entity);
                    next();
                }
                else {
                    next();
                }
            }
            else {
                next({ name: 'NotFound'});
            }
            break;
        case 'account':
            if (behid != undefined) {
                let be = user.billingEntities.find(c => c.hid == behid);
                if (be == undefined) {
                    next({ name: 'NotFound' });
                    break;
                }
                if (ehid != undefined) {
                    let entity = be.entityHIDs.find(c => c == ehid);
                    if (entity == undefined) {
                        next({ name: 'NotFound' });
                        break;
                    }
                }
            }
            next();
            break;
        default:
            next();
            break;
    }
}