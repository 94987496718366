export default [
    {
        name: 'lazyAI',
        icon: 'fa-solid fa-microchip',
        path: '/entity/:ehid/lazyai',
        type: 'item',
        disable: 'entity',
        tourId: 'sb-entity-menu-lazyai',
        show: [],
    },
    {
        name: 'reports',
        icon: 'fa-solid fa-chart-pie',
        path: '/entity/:ehid/report',
        type: 'item',
        disable: 'entity',
        tourId: 'sb-entity-menu-reports',
        show: [],
    },
    {
        name: 'employees',
        icon: 'fa-solid fa-users',
        path: '/entity/:ehid/hvs/groups',
        type: 'item',
        disable: 'entity',
        tourId: 'sb-entity-menu-users',
        show: [],
    },
    {
        name: 'campaigns',
        icon: 'fa-solid fa-chart-gantt',
        path: '/entity/:ehid/campaigns',
        type: 'item',
        disable: 'entity',
        tourId: 'sb-entity-menu-campaigns',
        show: [],
    },
    {
        name: 'setup',
        icon: 'fa-solid fa-screwdriver-wrench',
        path: '/entity/:ehid/setup',
        type: 'item',
        disable: 'entity',
        tourId: 'sb-entity-menu-setup',
        show: [],
    },
    {
        name: 'campBlocks',
        icon: 'fa-solid fa-cube',
        path: '/entity/:ehid/campaignblocks',
        type: 'item',
        disable: 'entity',
        tourId: 'sb-entity-menu-cblocks',
        show: [],
    },
    {
        name: 'campTemplates',
        icon: 'fa-solid fa-cubes',
        path: '/entity/:ehid/campaigntemplates',
        type: 'item',
        disable: 'entity',
        tourId: 'sb-entity-menu-ctemplates',
        show: ['manualControl'],
    },
    {
        name: 'training',
        icon: 'fa-solid fa-graduation-cap',
        path: '/entity/:ehid/training',
        type: 'item',
        disable: 'entity',
        tourId: 'sb-entity-menu-training',
        show: [],
    },
    {
        name: 'sep2',
        type: 'separator',
        show: [],
    },
    {
        name: 'helpSupport',
        icon: 'fa-solid fa-circle-question',
        path: '/helpsupport',
        type: 'item',
        show: [],
    },
]
