<template>
    <div class="paymentresult-page" >
        <div class="wrapper wrapper-full-page">
            <div class="full-page paymentresult-page section-image">
                <div class="content" >
                    <div class="container">
                        <div class="col-lg-6 col-md-8 ml-auto mr-auto text-center">
                            <card >
                                <div v-if="sessionStatus === 'success'" ref="content">
                                    <el-result :title="$t('paymentResultPage.processingHeader')" subTitle="" v-if="paymentStatus === 'none'">
                                        <template slot="icon">
                                            <i class="fa-4x fa-regular fa-credit-card fa-beat-fade text-info"></i>
                                        </template>
                                        <template slot="extra">
                                            <!--<router-link class="btn btn-info" :to="{ name: 'beSubs', params:{ behid: beHID}}">
                                                Back
                                            </router-link>-->
                                        </template>
                                    </el-result>

                                    <el-result icon="error" :title="$t('paymentResultPage.failedHeader')" :subTitle="errorMsg" v-if="paymentStatus === 'error'">
                                        <template slot="extra">
                                            <router-link class="btn btn-info" :to="{ name: 'beSubs', params:{ behid: beHID}}">
                                                {{ $t('paymentResultPage.btnOk')}}
                                            </router-link>
                                        </template>
                                    </el-result>

                                    <el-result icon="success" :title="$t('paymentResultPage.successHeader')" subTitle="" v-if="paymentStatus === 'success'">
                                        <template slot="extra">
                                            <router-link class="btn btn-info" :to="{ name: 'beSubs', params:{ behid: beHID}}">
                                                {{ $t('paymentResultPage.btnOk')}}
                                            </router-link>
                                        </template>
                                    </el-result>
                                </div>

                                <div v-if="sessionStatus === 'cancel'">
                                    <el-result icon="error" :title="$t('paymentResultPage.cancelHeader')" 
                                               :subTitle="$t('paymentResultPage.cancelText')">
                                        <template slot="extra">
                                            <router-link class="btn btn-info" :to="{ name: 'beSubs', params:{ behid: beHID}}">
                                                {{ $t('paymentResultPage.btnOk')}}
                                            </router-link>
                                        </template>
                                    </el-result>
                                </div>

                            </card>
                        </div>
                    </div>
                </div>

                <app-footer>
                </app-footer>
                <div class="full-page-background" style="background-image: url(static/img/background/lp-background.png) "></div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Card, Button, } from 'src/components/UIComponents';
    import { Result } from 'element-ui'
    import AppFooter from './Layout/AppFooter'
    import AppNavbar from './Layout/AppNavbar'

    import swal from 'sweetalert2'

    export default {
        components: {
            Card,
            AppNavbar,
            [Result.name]: Result,
            AppFooter,
            [Button.name]: Button
        },
        data() {
            return {
                sessionStatus: 'nan',
                sessionID: null,
                beHID: null,
                paymentStatus: 'none',
                errorMsg: "",
            }
        },
        methods: {
            checkSession() {
                let vueThis = this;
                this.axios.post(this.$root.config.billingEntity.checkSubscriptionPaymentUrl(this.beHID), null, { params: { sessionID: this.sessionID } })
                    .then(response => {
                        vueThis.paymentStatus = 'success';
                    }).catch(function (error) {
                        if (error.response.status == 400) {
                            vueThis.paymentStatus = 'error';
                            let respMsg = error.response.data;
                            swal.fire({
                                title: vueThis.$t('paymentResultPage.genericError'),
                                text: vueThis.$t('apiErrorCode.' + respMsg.code + '.msg'),
                                icon: vueThis.$t('apiErrorCode.' + respMsg.code + '.icon'),
                                showConfirmButton: true,
                                timer: vueThis.$root.config.alertTimer15,
                                timerProgressBar: true,
                            });
                        }
                        else {
                            vueThis.writeEx2Log(error);
                            vueThis.genericErrorAlert();
                        }
                    });
            },

        },
        mounted: function () {
            if (this.sessionStatus === 'success') {
                this.checkSession();
            }
        },
        created() {
            this.sessionStatus = this.$route.query.status;
            this.sessionID = this.$route.query.sessionID;
            this.beHID = this.$route.query.beHID;
        }
    }

</script>

<style scoped>



</style>