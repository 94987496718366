<template>
    <div class="wrapper">

        <side-bar ref="sidebarRef" type="sidebar" :sidebar-links="$sidebar.sidebarLinks" title="LazyPhish" class="d-print-none">
            <user-menu ref="userMenu"></user-menu>
            <template v-slot:maincontent>
                <entity-switch></entity-switch>
            </template>
        </side-bar>

        <div class="main-panel">
            <top-navbar ref="topnavbar" class="d-print-none"></top-navbar>

            <main-content @click.native="toggleSidebar" ref="maincontent">

            </main-content>

            <content-footer></content-footer>
        </div>
    </div>
</template>
<style lang="scss">
</style>
<script>
    import TopNavbar from './TopNavbar.vue'
    import ContentFooter from './ContentFooter.vue'
    import MainContent from './Content.vue'
    import UserMenu from 'src/components/UIComponents/SidebarPlugin/UserMenu.vue'
    import EntitySwitch from 'src/components/UIComponents/SidebarPlugin/EntitySwitch.vue'

    export default {
        components: {
            TopNavbar,
            ContentFooter,
            MainContent,
            UserMenu,
            EntitySwitch,
        },
        methods: {
            toggleSidebar() {
                if (this.$sidebar.showSidebar) {
                    this.$sidebar.displaySidebar(false)
                }
            },
        },
        data() {
            let vueThis = this;
            return {
            }
        }, 
        watch: {
            '$route': function () {
                this.$sidebar.displaySidebar(false);
            },
        },
    }

</script>
