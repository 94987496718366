import i18n from './lang'

export class CampaignTools {
    static Status_Running = "Running";
    static Status_Paused = "Paused";
    static Status_Finished = "Finished";
    static Status_Scheduled = "Scheduled";


    static GetStatusUIData(status) {
        switch (status) {
            case CampaignTools.Status_Scheduled:
                return {
                    labelId: "general.lpLibrary.campaign.status.scheduled",
                    icon: "fa-regular fa-clock",
                    order: 4,
                };
            case CampaignTools.Status_Running:
                return {
                    labelId: "general.lpLibrary.campaign.status.running",
                    icon: "nc-icon nc-button-play",
                    order: 3,
                };
            case CampaignTools.Status_Finished:
                return {
                    labelId: "general.lpLibrary.campaign.status.completed",
                    icon: "nc-icon nc-check-2",
                    order: 1,
                };
            case CampaignTools.Status_Paused:
                return {
                    labelId: "general.lpLibrary.campaign.status.paused",
                    icon: "nc-icon nc-button-pause",
                    order: 2,
                };
            default:
                return {
                    labelId: "general.lpLibrary.campaign.status.nan",
                    icon: "nc-icon nc-app",
                    order: 5,
                };
        }
    }

    static CalendarColors = [
        "#99e6e6",
        "#8080ff",
        "#df80ff",
        "#ff8080",
        "#ffb380",
        "#ffff80",
        "#99e699",
        "#80ffe5",
        "#80b3ff",
        "#ff80ff",
        "#ff80b3",
        "#ffe680",
        "#bfff80",
        "#80e5ff",
        "#aa80ff",
        "#ff80df",
        "#ffbf80",
        "#dfff80",
        "#80ffcc",
        "#80ccff",
        "#9fff80",
        "#809fff",
        "#ff80bf",
        "#80ff80",
    ];


    static ErrorCodes =  {
        SMSBudgetExhausted: 600001,
        NoActiveSubscription: 600002,
        LazyAIPaused: 600003,
        AdminAction: 600004,
    };

    static GetErrorMsg(errCode) {
        if (i18n.te('general.lpLibrary.campaignTools.errorCodes.' + errCode + '.msg')) {
            return i18n.t('general.lpLibrary.campaignTools.errorCodes.' + errCode + '.msg');
        }
        else {
            return i18n.t('general.lpLibrary.campaignTools.errMsg');
        }
    }
}

export class HvGroupTools {
    static Status_Active = "Active";

    static GetStatusUIData(status) {
        switch (status) {
            case HvGroupTools.Status_Active:
                return {
                    label: "Active",
                    icon: "nc-icon nc-button-play",
                };
            default:
                return {
                    label: "NaN",
                    icon: "nc-icon nc-app",
                };
        }
    }
}

export class SubscriptionTools {
    static Status_Paid = "Paid";
    static Status_Active = "Active";
    static Status_Closed= "Closed";
    static Status_Ordered = "Ordered";
    static Status_Cancelled = "Cancelled";

    static Type_Trial = "Trial";
    static Type_AIDrivenPlus = "AI Driven Plus";
    static Type_Managed = "Managed";

    static Type_TrialId = 1;
    static Type_AIDrivenPlusId = 2;
    static Type_ManagedId = 5;


    static getSubName(id) {
        switch (id) {
            case null:
                return "";
            case this.Type_TrialId:
                return this.Type_Trial;
            case this.Type_AIDrivenPlusId:
                return this.Type_AIDrivenPlus;
            case this.Type_ManagedId:
                return this.Type_Managed;
        }
        return "NaN";
    }
}

export class Tools {
    static Array_Diff(a1, a2) {
        var a = [], diff = [];

        for (var i = 0; i < a1.length; i++) {
            a[a1[i]] = true;
        }

        for (var i = 0; i < a2.length; i++) {
            if (a[a2[i]]) {
                delete a[a2[i]];
            } else {
                a[a2[i]] = true;
            }
        }

        for (var k in a) {
            if (k === "unique") continue;
            diff.push(k);
        }

        return diff;
    }

    static NumberFormat(num) {
        if (num == undefined || num == null) return "";
        let digits = (Math.round(num) - num) != 0;
        if (digits) {
            return num.toLocaleString('en-US', { minimumFractionDigits: 2 }).replace(',', ' ');
        }
        else {
            return num.toLocaleString('en-US', { minimumFractionDigits: 0 }).replace(',', ' ');
        }
    }

    static lowerCaseFirst(string) {        
        if (string.length === 0) {
            return string
        }
        return string[0].toLowerCase() + string.slice(1)
    }

    //static Countries() {
    //    return [
    //        {
    //            "Code": "AF",
    //            "Name": "Afghanistan"
    //        },
    //        {
    //            "Code": "AX",
    //            "Name": "\u00c5land Islands"
    //        },
    //        {
    //            "Code": "AL",
    //            "Name": "Albania"
    //        },
    //        {
    //            "Code": "DZ",
    //            "Name": "Algeria"
    //        },
    //        {
    //            "Code": "AS",
    //            "Name": "American Samoa"
    //        },
    //        {
    //            "Code": "AD",
    //            "Name": "Andorra"
    //        },
    //        {
    //            "Code": "AO",
    //            "Name": "Angola"
    //        },
    //        {
    //            "Code": "AI",
    //            "Name": "Anguilla"
    //        },
    //        {
    //            "Code": "AQ",
    //            "Name": "Antarctica"
    //        },
    //        {
    //            "Code": "AG",
    //            "Name": "Antigua and Barbuda"
    //        },
    //        {
    //            "Code": "AR",
    //            "Name": "Argentina"
    //        },
    //        {
    //            "Code": "AM",
    //            "Name": "Armenia"
    //        },
    //        {
    //            "Code": "AW",
    //            "Name": "Aruba"
    //        },
    //        {
    //            "Code": "AU",
    //            "Name": "Australia"
    //        },
    //        {
    //            "Code": "AT",
    //            "Name": "Austria"
    //        },
    //        {
    //            "Code": "AZ",
    //            "Name": "Azerbaijan"
    //        },
    //        {
    //            "Code": "BS",
    //            "Name": "Bahamas"
    //        },
    //        {
    //            "Code": "BH",
    //            "Name": "Bahrain"
    //        },
    //        {
    //            "Code": "BD",
    //            "Name": "Bangladesh"
    //        },
    //        {
    //            "Code": "BB",
    //            "Name": "Barbados"
    //        },
    //        {
    //            "Code": "BY",
    //            "Name": "Belarus"
    //        },
    //        {
    //            "Code": "BE",
    //            "Name": "Belgium"
    //        },
    //        {
    //            "Code": "BZ",
    //            "Name": "Belize"
    //        },
    //        {
    //            "Code": "BJ",
    //            "Name": "Benin"
    //        },
    //        {
    //            "Code": "BM",
    //            "Name": "Bermuda"
    //        },
    //        {
    //            "Code": "BT",
    //            "Name": "Bhutan"
    //        },
    //        {
    //            "Code": "BO",
    //            "Name": "Bolivia, Plurinational State of"
    //        },
    //        {
    //            "Code": "BQ",
    //            "Name": "Bonaire, Sint Eustatius and Saba"
    //        },
    //        {
    //            "Code": "BA",
    //            "Name": "Bosnia and Herzegovina"
    //        },
    //        {
    //            "Code": "BW",
    //            "Name": "Botswana"
    //        },
    //        {
    //            "Code": "BV",
    //            "Name": "Bouvet Island"
    //        },
    //        {
    //            "Code": "BR",
    //            "Name": "Brazil"
    //        },
    //        {
    //            "Code": "IO",
    //            "Name": "British Indian Ocean Territory"
    //        },
    //        {
    //            "Code": "BN",
    //            "Name": "Brunei Darussalam"
    //        },
    //        {
    //            "Code": "BG",
    //            "Name": "Bulgaria"
    //        },
    //        {
    //            "Code": "BF",
    //            "Name": "Burkina Faso"
    //        },
    //        {
    //            "Code": "BI",
    //            "Name": "Burundi"
    //        },
    //        {
    //            "Code": "KH",
    //            "Name": "Cambodia"
    //        },
    //        {
    //            "Code": "CM",
    //            "Name": "Cameroon"
    //        },
    //        {
    //            "Code": "CA",
    //            "Name": "Canada"
    //        },
    //        {
    //            "Code": "CV",
    //            "Name": "Cape Verde"
    //        },
    //        {
    //            "Code": "KY",
    //            "Name": "Cayman Islands"
    //        },
    //        {
    //            "Code": "CF",
    //            "Name": "Central African Republic"
    //        },
    //        {
    //            "Code": "TD",
    //            "Name": "Chad"
    //        },
    //        {
    //            "Code": "CL",
    //            "Name": "Chile"
    //        },
    //        {
    //            "Code": "CN",
    //            "Name": "China"
    //        },
    //        {
    //            "Code": "CX",
    //            "Name": "Christmas Island"
    //        },
    //        {
    //            "Code": "CC",
    //            "Name": "Cocos (Keeling) Islands"
    //        },
    //        {
    //            "Code": "CO",
    //            "Name": "Colombia"
    //        },
    //        {
    //            "Code": "KM",
    //            "Name": "Comoros"
    //        },
    //        {
    //            "Code": "CG",
    //            "Name": "Congo"
    //        },
    //        {
    //            "Code": "CD",
    //            "Name": "Congo, the Democratic Republic of the"
    //        },
    //        {
    //            "Code": "CK",
    //            "Name": "Cook Islands"
    //        },
    //        {
    //            "Code": "CR",
    //            "Name": "Costa Rica"
    //        },
    //        {
    //            "Code": "CI",
    //            "Name": "C\u00f4te d'Ivoire"
    //        },
    //        {
    //            "Code": "HR",
    //            "Name": "Croatia"
    //        },
    //        {
    //            "Code": "CU",
    //            "Name": "Cuba"
    //        },
    //        {
    //            "Code": "CW",
    //            "Name": "Cura\u00e7ao"
    //        },
    //        {
    //            "Code": "CY",
    //            "Name": "Cyprus"
    //        },
    //        {
    //            "Code": "CZ",
    //            "Name": "Czech Republic"
    //        },
    //        {
    //            "Code": "DK",
    //            "Name": "Denmark"
    //        },
    //        {
    //            "Code": "DJ",
    //            "Name": "Djibouti"
    //        },
    //        {
    //            "Code": "DM",
    //            "Name": "Dominica"
    //        },
    //        {
    //            "Code": "DO",
    //            "Name": "Dominican Republic"
    //        },
    //        {
    //            "Code": "EC",
    //            "Name": "Ecuador"
    //        },
    //        {
    //            "Code": "EG",
    //            "Name": "Egypt"
    //        },
    //        {
    //            "Code": "SV",
    //            "Name": "El Salvador"
    //        },
    //        {
    //            "Code": "GQ",
    //            "Name": "Equatorial Guinea"
    //        },
    //        {
    //            "Code": "ER",
    //            "Name": "Eritrea"
    //        },
    //        {
    //            "Code": "EE",
    //            "Name": "Estonia"
    //        },
    //        {
    //            "Code": "ET",
    //            "Name": "Ethiopia"
    //        },
    //        {
    //            "Code": "FK",
    //            "Name": "Falkland Islands (Malvinas)"
    //        },
    //        {
    //            "Code": "FO",
    //            "Name": "Faroe Islands"
    //        },
    //        {
    //            "Code": "FJ",
    //            "Name": "Fiji"
    //        },
    //        {
    //            "Code": "FI",
    //            "Name": "Finland"
    //        },
    //        {
    //            "Code": "FR",
    //            "Name": "France"
    //        },
    //        {
    //            "Code": "GF",
    //            "Name": "French Guiana"
    //        },
    //        {
    //            "Code": "PF",
    //            "Name": "French Polynesia"
    //        },
    //        {
    //            "Code": "TF",
    //            "Name": "French Southern Territories"
    //        },
    //        {
    //            "Code": "GA",
    //            "Name": "Gabon"
    //        },
    //        {
    //            "Code": "GM",
    //            "Name": "Gambia"
    //        },
    //        {
    //            "Code": "GE",
    //            "Name": "Georgia"
    //        },
    //        {
    //            "Code": "DE",
    //            "Name": "Germany"
    //        },
    //        {
    //            "Code": "GH",
    //            "Name": "Ghana"
    //        },
    //        {
    //            "Code": "GI",
    //            "Name": "Gibraltar"
    //        },
    //        {
    //            "Code": "GR",
    //            "Name": "Greece"
    //        },
    //        {
    //            "Code": "GL",
    //            "Name": "Greenland"
    //        },
    //        {
    //            "Code": "GD",
    //            "Name": "Grenada"
    //        },
    //        {
    //            "Code": "GP",
    //            "Name": "Guadeloupe"
    //        },
    //        {
    //            "Code": "GU",
    //            "Name": "Guam"
    //        },
    //        {
    //            "Code": "GT",
    //            "Name": "Guatemala"
    //        },
    //        {
    //            "Code": "GG",
    //            "Name": "Guernsey"
    //        },
    //        {
    //            "Code": "GN",
    //            "Name": "Guinea"
    //        },
    //        {
    //            "Code": "GW",
    //            "Name": "Guinea-Bissau"
    //        },
    //        {
    //            "Code": "GY",
    //            "Name": "Guyana"
    //        },
    //        {
    //            "Code": "HT",
    //            "Name": "Haiti"
    //        },
    //        {
    //            "Code": "HM",
    //            "Name": "Heard Island and McDonald Islands"
    //        },
    //        {
    //            "Code": "VA",
    //            "Name": "Holy See (Vatican City State)"
    //        },
    //        {
    //            "Code": "HN",
    //            "Name": "Honduras"
    //        },
    //        {
    //            "Code": "HK",
    //            "Name": "Hong Kong"
    //        },
    //        {
    //            "Code": "HU",
    //            "Name": "Hungary"
    //        },
    //        {
    //            "Code": "IS",
    //            "Name": "Iceland"
    //        },
    //        {
    //            "Code": "IN",
    //            "Name": "India"
    //        },
    //        {
    //            "Code": "ID",
    //            "Name": "Indonesia"
    //        },
    //        {
    //            "Code": "IR",
    //            "Name": "Iran, Islamic Republic of"
    //        },
    //        {
    //            "Code": "IQ",
    //            "Name": "Iraq"
    //        },
    //        {
    //            "Code": "IE",
    //            "Name": "Ireland"
    //        },
    //        {
    //            "Code": "IM",
    //            "Name": "Isle of Man"
    //        },
    //        {
    //            "Code": "IL",
    //            "Name": "Israel"
    //        },
    //        {
    //            "Code": "IT",
    //            "Name": "Italy"
    //        },
    //        {
    //            "Code": "JM",
    //            "Name": "Jamaica"
    //        },
    //        {
    //            "Code": "JP",
    //            "Name": "Japan"
    //        },
    //        {
    //            "Code": "JE",
    //            "Name": "Jersey"
    //        },
    //        {
    //            "Code": "JO",
    //            "Name": "Jordan"
    //        },
    //        {
    //            "Code": "KZ",
    //            "Name": "Kazakhstan"
    //        },
    //        {
    //            "Code": "KE",
    //            "Name": "Kenya"
    //        },
    //        {
    //            "Code": "KI",
    //            "Name": "Kiribati"
    //        },
    //        {
    //            "Code": "KP",
    //            "Name": "Korea, Democratic People's Republic of"
    //        },
    //        {
    //            "Code": "KR",
    //            "Name": "Korea, Republic of"
    //        },
    //        {
    //            "Code": "KW",
    //            "Name": "Kuwait"
    //        },
    //        {
    //            "Code": "KG",
    //            "Name": "Kyrgyzstan"
    //        },
    //        {
    //            "Code": "LA",
    //            "Name": "Lao People's Democratic Republic"
    //        },
    //        {
    //            "Code": "LV",
    //            "Name": "Latvia"
    //        },
    //        {
    //            "Code": "LB",
    //            "Name": "Lebanon"
    //        },
    //        {
    //            "Code": "LS",
    //            "Name": "Lesotho"
    //        },
    //        {
    //            "Code": "LR",
    //            "Name": "Liberia"
    //        },
    //        {
    //            "Code": "LY",
    //            "Name": "Libya"
    //        },
    //        {
    //            "Code": "LI",
    //            "Name": "Liechtenstein"
    //        },
    //        {
    //            "Code": "LT",
    //            "Name": "Lithuania"
    //        },
    //        {
    //            "Code": "LU",
    //            "Name": "Luxembourg"
    //        },
    //        {
    //            "Code": "MO",
    //            "Name": "Macao"
    //        },
    //        {
    //            "Code": "MK",
    //            "Name": "Macedonia, the Former Yugoslav Republic of"
    //        },
    //        {
    //            "Code": "MG",
    //            "Name": "Madagascar"
    //        },
    //        {
    //            "Code": "MW",
    //            "Name": "Malawi"
    //        },
    //        {
    //            "Code": "MY",
    //            "Name": "Malaysia"
    //        },
    //        {
    //            "Code": "MV",
    //            "Name": "Maldives"
    //        },
    //        {
    //            "Code": "ML",
    //            "Name": "Mali"
    //        },
    //        {
    //            "Code": "MT",
    //            "Name": "Malta"
    //        },
    //        {
    //            "Code": "MH",
    //            "Name": "Marshall Islands"
    //        },
    //        {
    //            "Code": "MQ",
    //            "Name": "Martinique"
    //        },
    //        {
    //            "Code": "MR",
    //            "Name": "Mauritania"
    //        },
    //        {
    //            "Code": "MU",
    //            "Name": "Mauritius"
    //        },
    //        {
    //            "Code": "YT",
    //            "Name": "Mayotte"
    //        },
    //        {
    //            "Code": "MX",
    //            "Name": "Mexico"
    //        },
    //        {
    //            "Code": "FM",
    //            "Name": "Micronesia, Federated States of"
    //        },
    //        {
    //            "Code": "MD",
    //            "Name": "Moldova, Republic of"
    //        },
    //        {
    //            "Code": "MC",
    //            "Name": "Monaco"
    //        },
    //        {
    //            "Code": "MN",
    //            "Name": "Mongolia"
    //        },
    //        {
    //            "Code": "ME",
    //            "Name": "Montenegro"
    //        },
    //        {
    //            "Code": "MS",
    //            "Name": "Montserrat"
    //        },
    //        {
    //            "Code": "MA",
    //            "Name": "Morocco"
    //        },
    //        {
    //            "Code": "MZ",
    //            "Name": "Mozambique"
    //        },
    //        {
    //            "Code": "MM",
    //            "Name": "Myanmar"
    //        },
    //        {
    //            "Code": "NA",
    //            "Name": "Namibia"
    //        },
    //        {
    //            "Code": "NR",
    //            "Name": "Nauru"
    //        },
    //        {
    //            "Code": "NP",
    //            "Name": "Nepal"
    //        },
    //        {
    //            "Code": "NL",
    //            "Name": "Netherlands"
    //        },
    //        {
    //            "Code": "NC",
    //            "Name": "New Caledonia"
    //        },
    //        {
    //            "Code": "NZ",
    //            "Name": "New Zealand"
    //        },
    //        {
    //            "Code": "NI",
    //            "Name": "Nicaragua"
    //        },
    //        {
    //            "Code": "NE",
    //            "Name": "Niger"
    //        },
    //        {
    //            "Code": "NG",
    //            "Name": "Nigeria"
    //        },
    //        {
    //            "Code": "NU",
    //            "Name": "Niue"
    //        },
    //        {
    //            "Code": "NF",
    //            "Name": "Norfolk Island"
    //        },
    //        {
    //            "Code": "MP",
    //            "Name": "Northern Mariana Islands"
    //        },
    //        {
    //            "Code": "NO",
    //            "Name": "Norway"
    //        },
    //        {
    //            "Code": "OM",
    //            "Name": "Oman"
    //        },
    //        {
    //            "Code": "PK",
    //            "Name": "Pakistan"
    //        },
    //        {
    //            "Code": "PW",
    //            "Name": "Palau"
    //        },
    //        {
    //            "Code": "PS",
    //            "Name": "Palestine, State of"
    //        },
    //        {
    //            "Code": "PA",
    //            "Name": "Panama"
    //        },
    //        {
    //            "Code": "PG",
    //            "Name": "Papua New Guinea"
    //        },
    //        {
    //            "Code": "PY",
    //            "Name": "Paraguay"
    //        },
    //        {
    //            "Code": "PE",
    //            "Name": "Peru"
    //        },
    //        {
    //            "Code": "PH",
    //            "Name": "Philippines"
    //        },
    //        {
    //            "Code": "PN",
    //            "Name": "Pitcairn"
    //        },
    //        {
    //            "Code": "PL",
    //            "Name": "Poland"
    //        },
    //        {
    //            "Code": "PT",
    //            "Name": "Portugal"
    //        },
    //        {
    //            "Code": "PR",
    //            "Name": "Puerto Rico"
    //        },
    //        {
    //            "Code": "QA",
    //            "Name": "Qatar"
    //        },
    //        {
    //            "Code": "RE",
    //            "Name": "R\u00e9union"
    //        },
    //        {
    //            "Code": "RO",
    //            "Name": "Romania"
    //        },
    //        {
    //            "Code": "RU",
    //            "Name": "Russian Federation"
    //        },
    //        {
    //            "Code": "RW",
    //            "Name": "Rwanda"
    //        },
    //        {
    //            "Code": "BL",
    //            "Name": "Saint Barth\u00e9lemy"
    //        },
    //        {
    //            "Code": "SH",
    //            "Name": "Saint Helena, Ascension and Tristan da Cunha"
    //        },
    //        {
    //            "Code": "KN",
    //            "Name": "Saint Kitts and Nevis"
    //        },
    //        {
    //            "Code": "LC",
    //            "Name": "Saint Lucia"
    //        },
    //        {
    //            "Code": "MF",
    //            "Name": "Saint Martin (French part)"
    //        },
    //        {
    //            "Code": "PM",
    //            "Name": "Saint Pierre and Miquelon"
    //        },
    //        {
    //            "Code": "VC",
    //            "Name": "Saint Vincent and the Grenadines"
    //        },
    //        {
    //            "Code": "WS",
    //            "Name": "Samoa"
    //        },
    //        {
    //            "Code": "SM",
    //            "Name": "San Marino"
    //        },
    //        {
    //            "Code": "ST",
    //            "Name": "Sao Tome and Principe"
    //        },
    //        {
    //            "Code": "SA",
    //            "Name": "Saudi Arabia"
    //        },
    //        {
    //            "Code": "SN",
    //            "Name": "Senegal"
    //        },
    //        {
    //            "Code": "RS",
    //            "Name": "Serbia"
    //        },
    //        {
    //            "Code": "SC",
    //            "Name": "Seychelles"
    //        },
    //        {
    //            "Code": "SL",
    //            "Name": "Sierra Leone"
    //        },
    //        {
    //            "Code": "SG",
    //            "Name": "Singapore"
    //        },
    //        {
    //            "Code": "SX",
    //            "Name": "Sint Maarten (Dutch part)"
    //        },
    //        {
    //            "Code": "SK",
    //            "Name": "Slovakia"
    //        },
    //        {
    //            "Code": "SI",
    //            "Name": "Slovenia"
    //        },
    //        {
    //            "Code": "SB",
    //            "Name": "Solomon Islands"
    //        },
    //        {
    //            "Code": "SO",
    //            "Name": "Somalia"
    //        },
    //        {
    //            "Code": "ZA",
    //            "Name": "South Africa"
    //        },
    //        {
    //            "Code": "GS",
    //            "Name": "South Georgia and the South Sandwich Islands"
    //        },
    //        {
    //            "Code": "SS",
    //            "Name": "South Sudan"
    //        },
    //        {
    //            "Code": "ES",
    //            "Name": "Spain"
    //        },
    //        {
    //            "Code": "LK",
    //            "Name": "Sri Lanka"
    //        },
    //        {
    //            "Code": "SD",
    //            "Name": "Sudan"
    //        },
    //        {
    //            "Code": "SR",
    //            "Name": "Suriname"
    //        },
    //        {
    //            "Code": "SJ",
    //            "Name": "Svalbard and Jan Mayen"
    //        },
    //        {
    //            "Code": "SZ",
    //            "Name": "Swaziland"
    //        },
    //        {
    //            "Code": "SE",
    //            "Name": "Sweden"
    //        },
    //        {
    //            "Code": "CH",
    //            "Name": "Switzerland"
    //        },
    //        {
    //            "Code": "SY",
    //            "Name": "Syrian Arab Republic"
    //        },
    //        {
    //            "Code": "TW",
    //            "Name": "Taiwan, Province of China"
    //        },
    //        {
    //            "Code": "TJ",
    //            "Name": "Tajikistan"
    //        },
    //        {
    //            "Code": "TZ",
    //            "Name": "Tanzania, United Republic of"
    //        },
    //        {
    //            "Code": "TH",
    //            "Name": "Thailand"
    //        },
    //        {
    //            "Code": "TL",
    //            "Name": "Timor-Leste"
    //        },
    //        {
    //            "Code": "TG",
    //            "Name": "Togo"
    //        },
    //        {
    //            "Code": "TK",
    //            "Name": "Tokelau"
    //        },
    //        {
    //            "Code": "TO",
    //            "Name": "Tonga"
    //        },
    //        {
    //            "Code": "TT",
    //            "Name": "Trinidad and Tobago"
    //        },
    //        {
    //            "Code": "TN",
    //            "Name": "Tunisia"
    //        },
    //        {
    //            "Code": "TR",
    //            "Name": "Turkey"
    //        },
    //        {
    //            "Code": "TM",
    //            "Name": "Turkmenistan"
    //        },
    //        {
    //            "Code": "TC",
    //            "Name": "Turks and Caicos Islands"
    //        },
    //        {
    //            "Code": "TV",
    //            "Name": "Tuvalu"
    //        },
    //        {
    //            "Code": "UG",
    //            "Name": "Uganda"
    //        },
    //        {
    //            "Code": "UA",
    //            "Name": "Ukraine"
    //        },
    //        {
    //            "Code": "AE",
    //            "Name": "United Arab Emirates"
    //        },
    //        {
    //            "Code": "GB",
    //            "Name": "United Kingdom"
    //        },
    //        {
    //            "Code": "US",
    //            "Name": "United States"
    //        },
    //        {
    //            "Code": "UM",
    //            "Name": "United States Minor Outlying Islands"
    //        },
    //        {
    //            "Code": "UY",
    //            "Name": "Uruguay"
    //        },
    //        {
    //            "Code": "UZ",
    //            "Name": "Uzbekistan"
    //        },
    //        {
    //            "Code": "VU",
    //            "Name": "Vanuatu"
    //        },
    //        {
    //            "Code": "VE",
    //            "Name": "Venezuela, Bolivarian Republic of"
    //        },
    //        {
    //            "Code": "VN",
    //            "Name": "Viet Nam"
    //        },
    //        {
    //            "Code": "VG",
    //            "Name": "Virgin Islands, British"
    //        },
    //        {
    //            "Code": "VI",
    //            "Name": "Virgin Islands, U.S."
    //        },
    //        {
    //            "Code": "WF",
    //            "Name": "Wallis and Futuna"
    //        },
    //        {
    //            "Code": "EH",
    //            "Name": "Western Sahara"
    //        },
    //        {
    //            "Code": "YE",
    //            "Name": "Yemen"
    //        },
    //        {
    //            "Code": "ZM",
    //            "Name": "Zambia"
    //        },
    //        {
    //            "Code": "ZW",
    //            "Name": "Zimbabwe"
    //        }
    //    ];
    //}
}

export class RoleEnum {
    static OwnerName = "Owner";
    static AdminName = "Admin";
    static ManagerName = "Manager";
    static ReaderName = "Reader";
    static SuperAdminName = "SuperAdmin";
    static DemoName = "Demo";
    static SuperAdminID = 1;
    static OwnerID = 2;
    static ManagerID = 3;
    static ReaderID = 4;
    static AdminID = 5;
    static DemoID = 6;

    static getRoleIcon(role) {
        switch (role) {
            case this.OwnerName:
                return this.OwnerIcon;
            case this.AdminName:
                return this.AdminIcon;
            case this.ManagerName:
                return this.ManagerIcon;
            case this.ReaderName:
                return this.ReaderIcon;
            case this.DemoName:
                return this.DemoIcon;
        }
        return "";
    }

    static OwnerIcon = "fa-solid fa-crown";
    static AdminIcon = "fa-solid fa-key";
    static ManagerIcon = "fa-solid fa-keyboard";
    static ReaderIcon = "fa-solid fa-eye";
    static SuperIcon = "fa-brands fa-superpowers";
    static DemoIcon = "fa-solid fa-flask-vial";
}

export class ReportTools {
    static Status_ForProcessing = "ForProcessing";
    static Status_ReportComplete = "ReportComplete";
}

export class MaturityLevelTools {
    static Color_Group0_Basic = "#e3e3e3"; //rgba(227, 227, 227, 1)
    static Color_Group1_Basic = "rgba(249,47,6,1)";
    static Color_Group2_Basic = "rgba(249,128,6,1)";
    static Color_Group3_Basic = "rgba(249,208,6,1)";
    static Color_Group4_Basic = "rgba(208,249,6,1)";
    static Color_Group5_Basic = "rgba(128,249,6,1)";

    static Color_Group0_T = "rgba(227, 227, 227, 0.2)";
    static Color_Group1_T = "rgba(249,47,6,0.2)";
    static Color_Group2_T = "rgba(249,128,6,0.2)";
    static Color_Group3_T = "rgba(249,208,6,0.2)";
    static Color_Group4_T = "rgba(208,249,6,0.2)";
    static Color_Group5_T = "rgba(128,249,6,0.2)";

    static Name_Group0 = "lp#sghv#0##";
    static Name_Group1 = "lp#sghv#1##";
    static Name_Group2 = "lp#sghv#2##";
    static Name_Group3 = "lp#sghv#3##";
    static Name_Group4 = "lp#sghv#4##";
    static Name_Group5 = "lp#sghv#5##";
    static Name_GroupA = "lp#sghv#A##";


    static getGroupByScore(score) {
        if (score == undefined || score == null) {
            return this.Name_Group0;
        }
        if (score <= 20) {
            return this.Name_Group1;
        }
        if (21 <= score && score <= 40) {
            return this.Name_Group2;
        }
        if (41 <= score && score <= 60) {
            return this.Name_Group3;
        }
        if (61 <= score && score <= 80) {
            return this.Name_Group4;
        }
        if ( 81 <= score) {
            return this.Name_Group5;
        }
    }

    static translateGroupName(name, isSystem) {
        if (isSystem) {
            return i18n.t("general.lpLibrary.hvGroup." + name);
        }
        return name;
    }

    static getGroupCssClass(name) {
        switch (name) {
            case this.Name_Group0: return 'g0';
            case this.Name_Group1: return 'g1';
            case this.Name_Group2: return 'g2';
            case this.Name_Group3: return 'g3';
            case this.Name_Group4: return 'g4';
            case this.Name_Group5: return 'g5';
            case this.Name_GroupA: return 'gA';
        }
        return "";
    }

}

export class LazyAITools {
    static Status_Stopped = "Stopped";
    static Status_Running = "Running";
    static Status_Paused = "Paused";

    static Status_Starting = "Starting";
    static Status_Stopping = "Stopping";
    static Status_Pausing = "Pausing";
    static Status_Resuming = "Resuming";


    //static SendingMode_M1 = "m1Mode";
    //static SendingMode_M2 = "m2Mode";
    //static SendingMode_M3 = "m3Mode";

    static SendingMode_UN = "unknownMode";


    static HvDetectionStatus = {
        NaN: "NaN",
        Active: "Active",
        Complete: "Complete",
    };
    static HvTrainingStatus = {
        NaN: "NaN",
        EmpClassification : "EmpClassification",
        AttacksSimulations : "AttacksSimulations",
        DataGathering : "DataGathering",
    };
}

export class ThemeTools {
    static Category_General = "General";
    static Category_Industry = "Industry";
    static Category_Technology = "Technology";
}
