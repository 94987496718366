<template>
    <el-select :disabled="disabled"
               class="lang-selector" :class="selectClass"
               v-model="lang" @change="langChange">
        <el-option v-for="option in languages"
                   class="select-default"
                   :value="option.value"
                   :label="option.label"
                   :key="option.label">
        </el-option>
    </el-select>
</template>

<script>
    import { Select, Option } from 'element-ui'


    export default {
        components: {
            [Select.name]: Select,
            [Option.name]: Option,
        },
        props: {
            defaultLang: {
                type: String,
                default: 'en',
            },
            disabled: {
                type: Boolean,
            },
            languages: {
                type: Array,
                require: true
            },
            schema: {
                type: String,
                default: 'light',
                description: 'light|dark'
            }
        },
        mounted() {
            this.lang = this.defaultLang;
        },
        data() {
            return {
                lang: '',
            }
        },
        methods: {
            langChange(value) {
                this.$root.cultureCode = value;
            }
        },
        watch: {
            '$root.cultureCode': function (value) {
                this.lang = value;
            }
        },
        computed: {
            selectClass() {
                if (this.schema === 'dark')
                    return 'select-dark';
                else
                    return 'select-light';
            }
        }
    }
</script>

<style scoped>
</style>