<template>
    <navbar v-model="showNavbar">
        <div class="navbar-wrapper">
            <!--<div class="navbar-minimize">
        <button id="minimizeSidebar" class="btn btn-icon btn-round" @click="minimizeSidebar">
            <i class="nc-icon nc-minimal-right text-center visible-on-sidebar-mini"></i>
            <i class="nc-icon nc-minimal-left text-center visible-on-sidebar-regular"></i>
        </button>
        </div>-->
            <div class="navbar-toggle">
                <navbar-toggle-button @click.native="toggleSidebar">

                </navbar-toggle-button>
            </div>
            <fg-input class="ml-3" v-if="isSuperAdmin">
                <el-select class="select-warning"
                           placeholder="Select account"
                           size="large"
                           v-model="selectedAccountId">
                    <el-option v-for="acc in accounts"
                               class="select-warning"
                               :value="acc.id"
                               :label="acc.email"
                               :key="acc.id">
                    </el-option>
                </el-select>
            </fg-input>

        </div>

        <template slot="navbar-menu">
            <ul class="navbar-nav">
                <li class="nav-item bg-danger" v-if="trialAlert">
                    <router-link class="nav-link btn-magnify trial-subs" :to="trialSubsUrl">
                        <i class="fa fa-exclamation-triangle"></i>
                        {{ $t('topNavbar.headerTrialSubs')}}
                    </router-link>
                </li>
                <li class="nav-item bg-warning" v-if="demoAlert">
                    <router-link class="nav-link btn-magnify demo" :to="{ name: 'Register'}">
                        <i class="fa fa-exclamation-triangle"></i>
                        {{ $t('topNavbar.headerDemoAccoount')}}
                    </router-link>
                </li>
                <li class="nav-item ml-2">
                    <langSelector :defaultLang="$root.cultureCode" :languages="$root.config.uiLanguages"></langSelector>
                </li>
            </ul>
        </template>
    </navbar>
</template>

<script>
    import { EventBus } from 'src/eventBus.js';
    import { SubscriptionTools } from 'src/lpLibrary.js'
    import LangSelector from 'src/components/UIComponents/LangSelector'


    import { Navbar, NavbarToggleButton } from 'src/components/UIComponents'
    import { Select, Option } from 'element-ui'
    import swal from 'sweetalert2'

    export default {
        components: {
            Navbar,
            NavbarToggleButton,
            [Option.name]: Option,
            [Select.name]: Select,
            LangSelector,
        },
        data() {
            return {
                activeNotifications: false,
                showNavbar: false,
                selectedAccountId: null,
                accounts: [],
                billingEntity: null,
            }
        },
        methods: {
            capitalizeFirstLetter(string) {
                return string.charAt(0).toUpperCase() + string.slice(1)
            },
            toggleNotificationDropDown() {
                this.activeNotifications = !this.activeNotifications
            },
            closeDropDown() {
                this.activeNotifications = false
            },
            toggleSidebar() {
                this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
            },
            hideSidebar() {
                this.$sidebar.displaySidebar(false)
            },
            minimizeSidebar() {
                this.$sidebar.toggleMinimize()
            },
            toggleNavbar() {
                this.showNavbar = !this.showNavbar;
            },
            loadAccounts() {
                let vueThis = this;
                this.axios.post(this.$root.config.account.getAccountsUrl(), null)
                    .then(response => {
                        this.accounts = response.data;                        
                    }).catch(function (error) {
                        vueThis.writeEx2Log(error);
                        vueThis.genericErrorAlert();
                    });
            },
            readBillingEntity() {
                if (this.$route.params.eid == undefined) {
                    this.billingEntity= null;
                }
                else {
                    let entityId = parseInt(this.$route.params.eid);
                    let entity = this.$store.getters.userState.entities.find(c => c.id == entityId);
                    if (entity == null || entity == undefined) {
                        this.billingEntity = null;
                        return;
                    }
                    this.billingEntity = this.$store.getters.userState.billingEntities.find(c => c.id == entity.beID);
                }
            },
            changeLanguage(value) {
                this.$root.cultureCode = value;
            },
        },
        watch: {
            selectedAccountId: function (val) {
                this.$store.commit('updateAccountHID', val);
                this.$store.dispatch('updateUser');
            },
            '$route.params': function () {
                this.readBillingEntity();
            },
            '$route': function () {
                this.showNavbar = false;
            },
        },
        computed: {
            isSuperAdmin() {
                return this.$store.state.user.isSuperAdmin;
            },
            trialAlert() {
                if (this.isSuperAdmin) return false;
                if (this.billingEntity == null || this.billingEntity == undefined) return false;
                return this.billingEntity.subscriptionTypeId === SubscriptionTools.Type_TrialId;
            },
            trialSubsUrl() {
                if (this.billingEntity == null || this.billingEntity == undefined) return "";
                return `/account/myorgs/${this.billingEntity.id}/subsctiprions`;
            },
            demoAlert() {
                return this.$store.state.user.isDemo;
            },
        },
        created: function () {
            if (this.$store.state.user.isSuperAdmin) {
                this.loadAccounts();
            }
            this.readBillingEntity();
        },
    }

</script>
<style>
    .trial-subs {
        color: white !important;
    }
    .demo {
        color: white !important;
    }
    .navbar-nav{
        border-radius: 12px !important;
    }
</style>
