<template>
    <div class="login-page">
        <app-navbar></app-navbar>
        <div class="wrapper wrapper-full-page">
            <div class="full-page login-page section-image">
                <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
                <div class="content">
                    <div class="container">
                        <div class="col-lg-4 col-md-6 ml-auto mr-auto">
                            <info-section class="mt-5"
                                          type="danger"
                                          title="Marketing"
                                          icon="nc-icon nc-alert-circle-i">
                                <h2 class="text-white mt-3">{{ $t('notFoundPage.header')}}</h2>
                                <small class="text-white">
                                    {{ $t('notFoundPage.text1')}}                                   
                                    <br>
                                    {{ $t('notFoundPage.text2')}} <router-link to="/">{{ $t('notFoundPage.linkToMainPage')}}</router-link>
                                </small>
                            </info-section>
                        </div>
                    </div>
                </div>
                <app-footer></app-footer>
                <div class="full-page-background" style="background-image: url(/static/img/background/lp-background.png)"></div>
            </div>
        </div>
    </div>
</template>
<script>
    import { Card, Checkbox, Button, InfoSection } from 'src/components/UIComponents';
    import AppNavbar from './../Pages/Layout/AppNavbar'
    import AppFooter from './../Pages/Layout/AppFooter'
    export default {
        components: {
            Card,
            AppNavbar,
            AppFooter,
            InfoSection,
            [Checkbox.name]: Checkbox,
            [Button.name]: Button
        },
        methods: {
            toggleNavbar() {
                document.body.classList.toggle('nav-open')
            },
            closeMenu() {
                document.body.classList.remove('nav-open')
                document.body.classList.remove('off-canvas-sidebar')
            }
        },
        beforeDestroy() {
            this.closeMenu()
        }
    }
</script>
<style>
</style>
