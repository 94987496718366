<template>
    <div class="login-page">
        <app-navbar></app-navbar>
        <div class="wrapper wrapper-full-page">
            <div class="full-page login-page section-image">
                <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
                <div class="content">
                    <div class="container">
                        <div class="col-lg-4 col-md-6 ml-auto mr-auto text-center">
                            <form>
                                <card type="resetpwd">
                                    <h3 slot="header" class="header text-center"> {{ $t('resetPwdPage.header')}}</h3>

                                    <fg-input v-model="formModel.email" addon-left-icon="nc-icon nc-email-85" :placeholder="$t('resetPwdPage.form.email.placeholder')" name="email"
                                              v-validate="modelValidations.email"
                                              :error="getError('email')"></fg-input>

                                    <div slot="footer">
                                        <p-button native-type="submit" type="warning" round block class="" @click.prevent="validate">{{ $t('resetPwdPage.btnReset')}}</p-button>
                                    </div>
                                </card>
                            </form>
                        </div>
                    </div>
                </div>
                <app-footer></app-footer>
                <div class="full-page-background" style="background-image: url(static/img/background/lp-background.png) "></div>
            </div>
        </div>
    </div>
</template>
<script>
    import { Card, Checkbox, Button } from 'src/components/UIComponents';
    import AppNavbar from './Layout/AppNavbar'
    import AppFooter from './Layout/AppFooter'
    import swal from 'sweetalert2'

    export default {
        components: {
            Card,
            AppNavbar,
            AppFooter,
            [Checkbox.name]: Checkbox,
            [Button.name]: Button
        },
        methods: {
            closeMenu() {
                document.body.classList.remove('nav-open')
                document.body.classList.remove('off-canvas-sidebar')
            },
            async recaptcha() {
                await this.$recaptchaLoaded();
                const token = await this.$recaptcha('resetpwd');
                this.formModel.recaptcha = token;
            },
            async sendEmail() {
                let vueThis = this;
                await this.recaptcha();
                this.axios.post(this.$root.config.account.resetPwdUrl(), this.formModel)
                    .then(response => {
                        swal.fire({
                            icon: 'success',
                            title: 'Reset password is finished',
                            text: 'Visit your mailbox and use the link in email for reset password',
                            showConfirmButton: true,
                            timer: vueThis.$root.config.alertTimer15,
                            timerProgressBar: true,
                        }).then((r) => {
                            this.$router.push('login');
                        });                        
                    })
                    .catch(function (error) {
                        if (error.response.status == 400) {
                            let respMsg = error.response.data;
                            switch (respMsg.code) {
                                case 100211:
                                    swal.fire({
                                        icon: 'error',
                                        title: 'Your account is locked',
                                        text: 'Please, contact us on our email address.',
                                        showConfirmButton: true,
                                        timer: vueThis.$root.config.alertTimer15,
                                        timerProgressBar: true,
                                    });
                                    break;
                                default:
                                    vueThis.genericErrorAlert();
                                    break;
                            }
                        }
                        else {
                            vueThis.writeEx2Log(error);
                            vueThis.genericErrorAlert();                         
                        }
                    });
            },
            getError(fieldName) {
                let familierName = this.$t('resetPwdPage.form.' + fieldName + '.familierName');
                var errMsg = this.errors.first(fieldName);
                if (errMsg === undefined) return errMsg;
                errMsg = errMsg.replace(fieldName, familierName);
                return errMsg;
            },
            validate() {
                this.$validator.validateAll().then(isValid => {
                    if (isValid) {
                        this.sendEmail();
                    }
                })
            },
        },
        data() {
            return {
                formModel: {
                    email: '',
                    recaptcha: ''
                },
                modelValidations: {
                    email: {
                        required: true,
                        email: true
                    },
                }
            }
        },
        beforeDestroy() {
            this.closeMenu()
        }
    }
</script>
<style>
</style>
