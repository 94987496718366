<template>
    <label class="file-select">
        <div class="btn btn-warning" style="height: 2.375rem;">
            <span v-if="value">Selected File: {{value.name}}</span>
            <span v-else>Select File</span>
        </div>
        <input type="file" @change="handleFileChange" />
    </label>
</template>

<script>
    export default {
        name: 'file-select',
        props: {
            value: File
        },

        methods: {
            handleFileChange(e) {
                this.$emit('input', e.target.files[0])
            }
        }
    }
</script>

<style scoped>
    .file-select > input[type="file"] {
        display: none;
    }
</style>