<template>
    <div class="login-page">
        <app-navbar></app-navbar>
        <div class="wrapper wrapper-full-page">
            <div class="full-page login-page section-image">
                <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
                <div class="content">
                    <div class="container">
                        <div class="col-lg-4 col-md-6 ml-auto mr-auto text-center">
                            <form>
                                <card type="login">
                                    <h3 slot="header" class="header text-center">{{ $t('changePwdPage.header')}}</h3>

                                    <fg-input type="password"
                                              name="newPassword" addon-left-icon="nc-icon nc-key-25"
                                              :placeholder="$t('changePwdPage.form.newPassword.placeholder')" :error="getError('newPassword')"
                                              v-model="formModel.newPassword" v-validate="modelValidations.newPassword" ref="newPassword">
                                    </fg-input>
                                    <fg-input type="password"
                                              name="confirmPassword" addon-left-icon="nc-icon nc-key-25"
                                              :placeholder="$t('changePwdPage.form.confirmPassword.placeholder')" :error="getError('confirmPassword')"
                                              v-model="formModel.confirmPassword" v-validate="modelValidations.confirmPassword">
                                    </fg-input>

                                    <p-button native-type="submit" slot="footer" type="warning" round block class="mb-3" @click.prevent="validate">{{ $t('changePwdPage.btnChange')}}</p-button>
                                </card>
                            </form>
                        </div>
                    </div>
                </div>
                <app-footer></app-footer>
                <div class="full-page-background" style="background-image: url(static/img/background/lp-background.png) "></div>
            </div>
        </div>
    </div>
</template>
<script>
    import { Card, Button } from 'src/components/UIComponents';
    import AppNavbar from './Layout/AppNavbar'
    import AppFooter from './Layout/AppFooter'
    import swal from 'sweetalert2'

    export default {
        components: {
            Card,
            AppNavbar,
            AppFooter,
            [Button.name]: Button
        },
        created: function () {
            this.formModel.accountHID = this.$route.query.accountHID;
            this.formModel.rid = this.$route.query.rid;
            if (this.formModel.accountHID == undefined || this.formModel.rid == undefined || this.formModel.accountHID == "" || this.formModel.rid == "") {
                this.$router.push('/login');
            }
            let vueThis = this;
            this.axios.post(this.$root.config.account.checkResetPwdUrl() + "?accountHID=" + this.$route.query.accountHID + "&rid=" + this.$route.query.rid, null)
                .then(response => {

                })
                .catch(function (error) {
                    if (error.response.status == 400) {
                        let respMsg = error.response.data;
                        swal.fire({
                            title: vueThis.$t('changePwdPage.checkResetUrlFailed'),
                            text: vueThis.$t('apiErrorCode.' + respMsg.code + '.msg'),
                            icon: vueThis.$t('apiErrorCode.' + respMsg.code + '.icon'),
                            showConfirmButton: true,
                            timer: vueThis.$root.config.alertTimer15,
                            timerProgressBar: true,
                        }).then((r) => {
                            vueThis.$router.push('/login');
                        });
                    }
                    else {
                        vueThis.writeEx2Log(error);
                    }
                })         
        },
        methods: {
            closeMenu() {
                document.body.classList.remove('nav-open')
                document.body.classList.remove('off-canvas-sidebar')
            },
            changePassword() {
                console.log('change');
                let vueThis = this;
                let loader = this.$loading.show(this.$root.config.getLoadingCfg(null));
                this.axios.post(this.$root.config.account.changePwdFromResetUrl(), this.formModel)
                    .then(response => {
                        loader.hide();
                        this.$store.commit('login', response.data.accountDetail);
                        this.$router.push({ name: 'aDashboard' });
                    })
                    .catch(function (error) {
                        loader.hide();
                        if (error.response.status == 400) {
                            console.log(error.response.data);
                            let respMsg = error.response.data;
                            swal.fire({
                                title: vueThis.$t('registerPage.failed'),
                                text: vueThis.$t('apiErrorCode.' + respMsg.code + '.msg'),
                                icon: vueThis.$t('apiErrorCode.' + respMsg.code + '.icon'),
                                showConfirmButton: true,
                                timer: vueThis.$root.config.alertTimer15,
                                timerProgressBar: true,
                            });
                        }
                        else {
                            vueThis.writeEx2Log(error);
                            vueThis.genericErrorAlert();
                        }
                    });
            },
            getError(fieldName) {
                let familierName = this.$t('changePwdPage.form.' + fieldName + '.familierName');

                let errMsg = this.errors.first(fieldName);
                if (errMsg === undefined) return errMsg;
                errMsg = errMsg.replace(fieldName, familierName);
                if (fieldName == "newPassword" && this.errors.items.some(c => c.field === "newPassword" && c.rule === "regex")) {
                    errMsg = this.$t('changePwdPage.form.newPassword.passwordRegexErrMsg');
                }
                if (fieldName == "confirmPassword" && this.errors.items.some(c => c.field === "confirmPassword" && c.rule === "regex")) {
                    errMsg = this.$t('changePwdPage.form.confirmPassword.passwordRegexErrMsg');
                }
                return errMsg;
            },
            validate() {
                this.$validator.validateAll().then(isValid => {
                    if (isValid) {
                        this.changePassword();
                    }
                })
            },
        },
        data() {
            var vueThis = this;
            return {
                isPossibleChange: true,
                errorCode: 0,
                formModel: {
                    accountHID: 0,
                    rid: '',
                    newPassword: '',
                    confirmPassword: ''
                },
                modelValidations: {
                    newPassword: {
                        required: true,
                        regex: vueThis.$root.config.passwordRegex,
                        max: 200,
                    },
                    confirmPassword: {
                        required: true,
                        confirmed: 'newPassword',
                        regex: vueThis.$root.config.passwordRegex,
                        max: 200,
                    },
                }
            }
        },
        beforeDestroy() {
            this.closeMenu()
        }
    }
</script>
<style>

</style>
