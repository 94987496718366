<template>
    <div class="validation-page" >
        <div class="wrapper wrapper-full-page">
            <div class="full-page validation-page section-image">
                <div class="content" >
                    <div class="container">
                        <div class="col-lg-6 col-md-8 ml-auto mr-auto text-center">
                            <card>
                                <el-result :title="$t('validateDomainPage.processingHeader')" subTitle="" v-if="resultValidation === 'none'">
                                    <template slot="icon">
                                        <i class="fa-4x text-info fa-solid fa-spinner fa-spin-pulse"></i>
                                    </template>
                                    <template slot="extra">
                                        <!--<router-link class="btn btn-info" :to="{ name: 'beSubs', params:{ behid: beHID}}">
                                            Back
                                        </router-link>-->
                                    </template>
                                </el-result>

                                <el-result icon="error" :title="$t('validateDomainPage.failedHeader')" :subTitle="errorMsg" v-if="resultValidation === 'error'">
                                    <template slot="extra">
                                        <router-link class="btn btn-info" :to="{ name: 'Login' }">
                                            {{ $t('validateDomainPage.btnOk')}}
                                        </router-link>
                                    </template>
                                </el-result>

                                <el-result icon="success" :title="$t('validateDomainPage.successHeader')" subTitle="" v-if="resultValidation === 'success'">
                                    <template slot="extra">
                                        <router-link class="btn btn-info" :to="{ name: 'Login' }">
                                            {{ $t('validateDomainPage.btnOk')}}
                                        </router-link>
                                    </template>
                                </el-result>
                            </card>
                        </div>
                    </div>
                </div>

                <app-footer>
                </app-footer>
                <div class="full-page-background" style="background-image: url(static/img/background/lp-background.png) "></div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Card, Button, } from 'src/components/UIComponents';
    import { Result } from 'element-ui'
    import AppFooter from './Layout/AppFooter'
    import AppNavbar from './Layout/AppNavbar'

    import swal from 'sweetalert2'

    export default {
        components: {
            Card,
            AppNavbar,
            [Result.name]: Result,
            AppFooter,
            [Button.name]: Button
        },
        data() {
            return {
                resultValidation: 'none',
                errorMsg:'',
            }
        },
        methods: {
            validateDomain(guid) {
                let vueThis = this;
                this.axios.post(this.$root.config.entity.validateDomainUrl(), null, { params: { guid: guid } })
                    .then(response => {
                        vueThis.resultValidation = 'success';
                    }).catch(function (error) {
                        if (error.response.status == 400) {
                            vueThis.resultValidation = 'error';
                        }
                        else {
                            vueThis.writeEx2Log(error);
                            vueThis.genericErrorAlert();
                        }
                    });
            },

        },
        mounted: function () {
            this.validateDomain(this.$route.query.guid);
        },
    }

</script>

<style scoped>



</style>