<template>
    <navbar navbar-menu-classes="navbar-right" v-model="showMenu">
        <div class="navbar-wrapper">
            <a class="navbar-brand" :href="publicWebUrl">
                <span class="logo-img">
                    <img :src="logo" alt="">
                </span>
                LazyPhish
            </a>
        </div>
        <template slot="navbar-menu">
            <router-link to="/register" class="nav-item" tag="li">
                <a class="nav-link">
                    <i class="fa fa-user-plus"></i> {{ $t('appNavbar.linkRegister')}}
                </a>
            </router-link>
            <router-link to="/login" class="nav-item" tag="li">
                <a class="nav-link">
                    <i class="fa fa-sign-in"></i> {{ $t('appNavbar.linkLogin')}}
                </a>
            </router-link>
            <div class="ml-2">
                <langSelector :defaultLang="$root.cultureCode" :languages="$root.config.uiLanguages" schema="dark" ></langSelector>
            </div>
        </template>
    </navbar>
</template>

<script>
    import { Navbar } from 'src/components/UIComponents'
    import LangSelector from 'src/components/UIComponents/LangSelector'

    export default {
        name: 'main-navbar',
        components: {
            Navbar,
            LangSelector
        },
        props: {
            logo: {
                type: String,
                default: '/static/img/lp-logo.png',
                description: 'Sidebar Logo'
            },
        },
        data() {
            return {
                showMenu: false
            }
        },
        methods: {
            changeLanguage(value) {
                this.$root.cultureCode = value;
            },
        },
        computed: {
            publicWebUrl() {
                return this.$root.config.getPublicWebUrl;
            }
        }
    }
</script>

<style scoped>
</style>
